import React from "react";
import Provider_Header from "./Provider_Header";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const Provider_Accept = () => {

    const providertoken = localStorage.getItem("providertoken");
    const [isSubmitting, setIsSubmitting] = useState({});
    const [isSubmittingW, setIsSubmittingW] = useState({});
    const [isSubmittingP, setIsSubmittingP] = useState({});
    const [isSubmittingS, setIsSubmittingS] = useState({});
    const [isSubmittingC, setIsSubmittingC] = useState({});
    const [isSubmittingD, setIsSubmittingD] = useState({});
    const [isSubmittingDes, setIsSubmittingDes] = useState({});
    const [isSubmittingCall, setIsSubmittingCall] = useState({});
    const [isSubmittingMeet, setIsSubmittingMeet] = useState({});

    const [providerorder, setProviderorder] = useState([]);
    useEffect(() => {

        axios.get(`${process.env.REACT_APP_URL}/provider/accepted_orders`, { headers: { 'providertoken': providertoken } }).then(function (response) {
            // handle success
            console.log(response.data, "op");
            setProviderorder(response.data.providerorder);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    const { id } = useParams();


    var acceptorder = () => {

        axios.get(`${process.env.REACT_APP_URL}/provider/accepted_orders`, { headers: { 'providertoken': providertoken } }).then(function (response) {
            // handle success
            console.log(response.data, "op");
            setProviderorder(response.data.providerorder);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }



    //---------------------------------------------------------------------|   CALL   |------------------------------------------------------------------------------------------------//
    const handleCall = (id) => {
        // data get karavava mate
        axios.post(`${process.env.REACT_APP_URL}/provider/tracking/${id}/call`, { body: "call" }, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                // handle success
                console.log(response.data, "call:::");
                acceptorder()
                setIsSubmittingCall((prevIsSubmittingCall) => ({ ...prevIsSubmittingCall, [id]: true }));
                // setProviderorder(response.data.updatedData);
                // window.location.reload('')

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }

    //---------------------------------------------------------------------|   MEETING   |------------------------------------------------------------------------------------------------//

    const handleMeeting = (id) => {
        // data get karavava mate
        axios.post(`${process.env.REACT_APP_URL}/provider/tracking/${id}/meeting`, { body: "meeting" }, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                // handle success
                console.log(response.data, "meeting:::");
                window.location.reload('')
                setIsSubmittingMeet(true);
                setIsSubmittingMeet((prevIsSubmittingMeet) => ({ ...prevIsSubmittingMeet, [id]: true }));
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            }).then(function (response) {
                console.log();
            })


    }



    //---------------------------------------------------------------------|   DEAL   |------------------------------------------------------------------------------------------------//

    const handleDeal = (id) => {
        // data get karavava mate
        axios.post(`${process.env.REACT_APP_URL}/provider/tracking/${id}/deal`, { body: "deal" }, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                // handle success
                console.log(response.data, "deal:::");
                // window.location.reload('')
                acceptorder()
                setIsSubmittingD((prevIsSubmittingD) => ({ ...prevIsSubmittingD, [id]: true }));

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }

    const handleCancel = (id, itemId, itemsId) => {

        const data = {
            orderid: id,
            providerid: itemId,
            trackingorderid: itemsId
        }
        axios.post(`${process.env.REACT_APP_URL}/provider/cancelhalforder`, data, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                // console.log(response.data, "deal:::");
                acceptorder()
                setIsSubmittingC((prevIsSubmittingC) => ({ ...prevIsSubmittingC, [id]: true }));
            })
            .catch(function (error) {
                console.log(error);
            })
    }



    //---------------------------------------------------------------------|   AMOUNT   |------------------------------------------------------------------------------------------------//

    const [dealamount, setDealamount] = useState({}); // Use an object to store values for each item

    const handleAmount = (id) => {
        console.log(dealamount, "ididididid");
        if (Object.keys(dealamount).length == 0) {
            return {}
        }
        else {

            axios
                .post(`${process.env.REACT_APP_URL}/provider/tracking/${id}/amount`, {
                    body: "amount",
                    dealamount: dealamount[id] // Get the dealamount for the specific item
                }, { headers: { 'providertoken': providertoken } })
                .then(function (response) {
                    console.log(response.data, "dealamount:::");
                    // window.location.reload('');
                    acceptorder()
                    setIsSubmittingS((prevIsSubmittingS) => ({ ...prevIsSubmittingS, [id]: true }));
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    const [descrip, setDescrip] = useState({}); // Use an object to store values for each item

    const handleDesc = (orderItem) => {
        console.log(descrip, "description");

        const description = descrip[orderItem.trackingorder[0]._id];

        if (!description || description.length === 0) {
            return;
        }

        const id = orderItem.trackingorder[0]._id; // Assuming `orderItem` contains the necessary information

        axios
            .post(`${process.env.REACT_APP_URL}/provider/description/${id}`, {
                body: "description",
                description: description,
            }, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                console.log(response.data, "description:::");
                setDescrip({ ...descrip, [id]: '' });
                acceptorder();
                setIsSubmittingDes((prevIsSubmittingDes) => ({ ...prevIsSubmittingDes, [id]: true }));
            })
            .catch(function (error) {
                console.log(error);
            });
    };



    //---------------------------------------------------------------------|   WORK   |------------------------------------------------------------------------------------------------//

    const handleWork = (id) => {
        // data get karavava mate
        axios.post(`${process.env.REACT_APP_URL}/provider/tracking/${id}/work`, { body: "work" }, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                // handle success
                console.log(response.data, "work:::");
                // window.location.reload('')
                acceptorder()
                setIsSubmittingW((prevIsSubmittingW) => ({ ...prevIsSubmittingW, [id]: true }));

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }



    //---------------------------------------------------------------------|   PAYMENT   |------------------------------------------------------------------------------------------------//

    const handlePayMent = (id) => {
        // data get karavava mate
        axios.post(`${process.env.REACT_APP_URL}/provider/tracking/${id}/payment`, { body: "Payment" }, { headers: { 'providertoken': providertoken } })
            .then(function (response) {
                // handle success
                console.log(response.data, "payment:::");
                // window.location.reload('')
                acceptorder()
                setIsSubmittingP((prevIsSubmittingP) => ({ ...prevIsSubmittingP, [id]: true }));

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }

    useEffect(() => {
        // Initialize state variables for each item when providerorder changes
        const initializeIsSubmitting = (action) => {
            const initialState = {};
            providerorder.forEach((item) => {
                initialState[item._id] = false;
            });
            action(initialState);
        };

        initializeIsSubmitting(setIsSubmittingCall);
        initializeIsSubmitting(setIsSubmittingMeet);
        initializeIsSubmitting(setIsSubmittingD);
        initializeIsSubmitting(setIsSubmittingS);
        initializeIsSubmitting(setIsSubmittingDes);
        initializeIsSubmitting(setIsSubmittingW);
        initializeIsSubmitting(setIsSubmittingP);
    }, [providerorder]);

    return (
        <>
            <Provider_Header />

            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>Accept Order</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Orders</li>
                            </ol>
                        </nav>
                    </div>
                    <div>
                        <h5 className="fw-bold my-4">Number of orders : {providerorder.length}</h5>
                    </div>
                    <section className="section dashboard">
                        <div className="row">
                            {/* Left side columns */}
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div class="collapse col-12" id="collapseExample"></div>
                                        <div className="card recent-sales overflow-auto">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="col-2 ms-5 me-3">
                                                    </div>
                                                    <div className="col-2">
                                                    </div>
                                                </div>

                                                <div className="table-responsive mt-2">
                                                    <table className="table">
                                                        <thead className="table-primary text-center align-middle">

                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Orderid</th>
                                                                <th>Customer Name</th>
                                                                <th>Customer Number</th>
                                                                <th>Order Detail</th>
                                                                <th>Call</th>
                                                                <th>Meeting</th>
                                                                <th>Deal</th>
                                                                <th>Amount</th>
                                                                <th>Completed Order Details</th>
                                                                <th>Work</th>
                                                                <th>Payment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-light text-center align-middle">
                                                            <>
                                                                {providerorder &&
                                                                    providerorder.map((item, i) => (
                                                                        <tr key={i} >
                                                                            <td>
                                                                                {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                            </td>
                                                                            <td>
                                                                                {item.no}
                                                                            </td>
                                                                            <td>
                                                                                {item.otherName}
                                                                            </td>
                                                                            <td>
                                                                                {item.otherNumber}
                                                                            </td>
                                                                            <td>
                                                                                <Link to={`/complete_orderdetails/${item._id}`}>
                                                                                    <button className="form-control btn btn-primary">more</button>
                                                                                </Link>
                                                                            </td>
                                                                            <td data-th="">
                                                                                {item.trackingorder[0].call === true ? <span><i class="bi-check-lg fs-2 text-success"></i></span> :
                                                                                    <a href={`tel:${item.otherNumber}`}>
                                                                                        <button type="button" onClick={() => handleCall(item._id)} className="btn btn-primary btn-sm" disabled={isSubmittingCall[item._id]} style={{ whiteSpace: 'nowrap' }}>Call Done</button>
                                                                                    </a>}
                                                                            </td>
                                                                            <td data-th="">
                                                                                {item.trackingorder[0].meeting === true ? <span><i class="bi-check-lg fs-2 text-success"></i></span> :
                                                                                    <button type="button" onClick={() => handleMeeting(item._id)} className="btn btn-primary btn-sm" disabled={isSubmittingMeet[item._id]} style={{ whiteSpace: 'nowrap' }}>Meeting Done</button>}
                                                                            </td>
                                                                            <td data-th="">
                                                                                {item.trackingorder[0].deal === true ? <span><i class="bi-check-lg fs-2 text-success"></i></span> :
                                                                                    (<>
                                                                                        <button type="button" onClick={() => handleDeal(item._id)} className="btn btn-primary btn-sm" disabled={isSubmittingD[item._id]}>Deal Done</button>
                                                                                        <button type="button" onClick={() => handleCancel(item._id, item.providerid, item.trackingorder[0]._id)} className="btn btn-danger btn-sm mt-2" disabled={isSubmittingC[item._id]}>Cancel</button>
                                                                                    </>)}
                                                                            </td>

                                                                            <td data-th="">
                                                                                {item.trackingorder[0].amount === true ? (
                                                                                    <span>
                                                                                        <i className="bi-check-lg fs-2 text-success"></i>
                                                                                    </span>
                                                                                ) : (
                                                                                    <div className="input-group mb-3 d-flex justify-content-center">
                                                                                        <form>
                                                                                            <input
                                                                                                required
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={dealamount[item._id] || dealamount || ''}
                                                                                                onChange={(e) => setDealamount({ ...dealamount, [item._id]: e.target.value })}
                                                                                                placeholder="Enter Deal Amount"
                                                                                                aria-label="Recipient's username"
                                                                                                aria-describedby="button-addon2"
                                                                                                min="0"
                                                                                            />
                                                                                            <button
                                                                                                className="btn btn-primary mt-2"
                                                                                                onClick={() => handleAmount(item._id)}
                                                                                                type="button"
                                                                                                id="button-addon2"
                                                                                                disabled={isSubmittingS[item._id]}
                                                                                            >
                                                                                                Submit
                                                                                            </button>
                                                                                        </form>
                                                                                    </div>
                                                                                )}

                                                                            </td>
                                                                            <td data-th="">
                                                                                {item.trackingorder[0].description.length > 0 ? <span><i class="bi-check-lg fs-2 text-success"></i></span> :
                                                                                    (<>
                                                                                        <div className="input-group mb-3 d-flex justify-content-center">
                                                                                            <form>
                                                                                                <input
                                                                                                    required
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Deal Description"
                                                                                                    value={descrip[item.trackingorder[0]._id] || ''}

                                                                                                    onChange={(e) => {
                                                                                                        console.log('Current descrip state:', descrip);
                                                                                                        setDescrip({ ...descrip, [item.trackingorder[0]._id]: e.target.value });
                                                                                                    }}

                                                                                                    aria-describedby="button-addon2"
                                                                                                />

                                                                                                <button
                                                                                                    className="btn btn-primary mt-2"
                                                                                                    type="button"
                                                                                                    onClick={() => handleDesc(item)}
                                                                                                    disabled={isSubmittingDes[item.trackingorder[0]._id]}
                                                                                                    id="button-addon2"
                                                                                                >
                                                                                                    Submit
                                                                                                </button>
                                                                                            </form>
                                                                                        </div>
                                                                                    </>)}
                                                                            </td>

                                                                            <td data-th="">
                                                                                {item.trackingorder[0].work === true ? <span><i class="bi-check-lg fs-2 text-success"></i></span> :
                                                                                    <button type="button" onClick={() => handleWork(item._id)} className="btn btn-primary btn-sm" disabled={isSubmittingW[item._id]} style={{ whiteSpace: 'nowrap' }}>Work Done</button>}
                                                                            </td>
                                                                            <td data-th="">
                                                                                {item.trackingorder[0].payment === true ? <span><i class="bi-check-lg fs-2 text-success"></i></span> :
                                                                                    <button type="button" onClick={() => handlePayMent(item._id)} className="btn btn-primary btn-sm" disabled={isSubmittingP[item._id]} style={{ whiteSpace: 'nowrap' }}>PayMent Done</button>}
                                                                            </td>
                                                                        </tr>

                                                                    ))}
                                                            </>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        </>
    )
}
export default Provider_Accept;