import React, { useEffect, useState } from "react";
import Provider_Header from "./Provider_Header";
import axios from "axios";
import { Link } from "react-router-dom";

const Provider_Commision = () => {

  const providertoken = localStorage.getItem("providertoken");
  const [completedOrders, setCompletedOrders] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    companyQRCode: '',
    selectedProduct: '',
    paymentScreenshot: null,
    paymentAmount: '',
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/provider/completed_order`, { headers: { 'providertoken': providertoken } })
      .then(function (response) {
        // handle success
        console.log(response.data);
        setCompletedOrders(response.data.completed);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [providertoken]);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSend = new FormData();
    dataToSend.append('companyQRCode', formData.companyQRCode);
    dataToSend.append('selectedProduct', formData.selectedProduct);
    dataToSend.append('screenshot', formData.paymentScreenshot);
    dataToSend.append('paymentAmount', formData.paymentAmount);

    axios.post(`${process.env.REACT_APP_URL}/provider/payment`, dataToSend, { headers: { 'providertoken': providertoken } })
      .then(function (response) {
        // handle success
        console.log(response.data);
        setFormData({
          companyQRCode: '',
          selectedProduct: '',
          paymentScreenshot: null,
          paymentAmount: '',
        });
        setIsSubmitting(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  };


  const [orders, setOrders] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/provider/complete_order`, { headers: { 'providertoken': providertoken } }).then(function (response) {
      // handle success
      console.log(response.data, "sdjnfjsj");
      setOrders(response.data.completed);
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, [])

  const [selectedOrderId, setSelectedOrderId] = useState('');
  const selectedOrder = orders.find((order) => order.no === parseInt(selectedOrderId));

  const handleSelectChange = (event) => {
    setSelectedOrderId(event.target.value);
  };

  return (
    <>
      <Provider_Header />

      <div>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1 className="text-start">Company Commission</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item active">Company Commission</li>
              </ol>
            </nav>
          </div>{/* End Page Title */}
          <section className="section dashboard">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div class="collapse col-12" id="collapseExample">

                    </div>
                    <div className="card recent-sales overflow-auto">
                      <div className="card-body">
                        <div className="d-flex mb-3">

                          <div className="col-2 ms-5 me-3">
                          </div>
                          <div className="col-2">
                          </div>
                        </div>


                        <div className="table-responsive mt-2">
                          <select onChange={handleSelectChange} className="w-100">
                            <option value="" selected disabled>Select an order</option>
                            {orders &&
                              orders.map((item, i) => (
                                <option key={i} value={item.no}>
                                  Order Id: {item.no}, Order Date: {item.createdAt.slice(0, 10)}, Order Deal Amount: {item.trackingorder[0].dealamount}, Pay out to Company Commission: {item.trackingorder[0].providercommission}
                                </option>
                              ))}
                          </select>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section dashboard">
            <div className="row justify-content-center">
              <div className="col-6">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12">
                    <label htmlFor="inputAddress" className="form-label">Company QR Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company QR code"
                      name="companyQRCode"
                      value={formData.companyQRCode}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="inputState" className="form-label">Compony UPI Id</label>
                    <input className="form-control" value="123@ok.axis"/>
                  </div>

                  <div className="col-12">
                    <label htmlFor="inputAddress" className="form-label">Payment ScreenShot</label>
                    <input
                      type="file"
                      className="form-control"
                      name="paymentScreenshot"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12">
                    <label className="form-label">Payment Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Payment Amount"
                      name="paymentAmount"
                      value={formData.paymentAmount}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Done</button>
                  </div>
                </form>

              </div>
            </div>
          </section>
        </main>{/* End #main */}
        
      </div>
    </>
  )

}
export default Provider_Commision;