import React from "react";
import UserHeader from "./UserHeader";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const CompletedOrder = () => {

    const usertoken = localStorage.getItem("usertoken");

    const [order, setOrder] = useState('');
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/user/completed_order`, { headers: { 'usertoken': usertoken } }).then(function (response) {
            // handle success
            console.log(response.data);
            setOrder(response.data.order);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    var totalDealAmount=""
    var totalMemberCommission=""
    if (Array.isArray(order)) {
    // Calculate totals
     totalDealAmount = order.reduce((total, item) => total + parseFloat(item.trackingorder[0].dealamount), 0);
     totalMemberCommission = order.reduce((total, item) => total + parseFloat(item.trackingorder[0].memberCommission), 0);
        }

    return (
        <>
            <UserHeader />

            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>Completed Order</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Completed Order</li>
                            </ol>
                        </nav>
                    </div>
                    <div>
                        <h5 className="fw-bold my-4">Number of orders : {order.length}</h5>
                    </div>
                    <section className="section dashboard">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div class="collapse col-12" id="collapseExample">

                                        </div>
                                        <div className="card recent-sales overflow-auto">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="col-7">
                                                        {/* <h5 className="card-title">Your Order</h5> */}
                                                    </div>
                                                    <div className="col-2 ms-5 me-3">
                                                    </div>
                                                    <div className="col-2">
                                                    </div>
                                                </div>

                                                <table className="table">
                                                    <tbody className="text-center">
                                                        <tr>
                                                            <th>Order Id</th>
                                                            <th>Order Date</th>
                                                            <th>Order Service</th>
                                                            <th>Order Deal Amount</th>
                                                            <th>Your Commission</th>
                                                            <th>details</th>

                                                        </tr>
                                                        {order &&
                                                            order.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                            {item.no}
                                                                    </td>
                                                                    <td>
                                                                        {new Date(item.updatedAt).toLocaleDateString('en-GB')}
                                                                    </td>

                                                                    <td>
                                                                            {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                    </td>

                                                                    <td>
                                                                            {item.trackingorder[0].dealamount}
                                                                    </td>
                                                                    <td>
                                                                            {item.trackingorder[0].memberCommission}
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/user_showorder/${item._id}`}>
                                                                            <button className="btn btn-primary">more</button>
                                                                        </Link>
                                                                    </td>

                                                                </tr>

                                                            ))}
                                                        <tr key="total">
                                                            <td colSpan="3"><h4 className="fw-bold">Total</h4></td>
                                                            <td>
                                                                <h6 className="fw-bold">
                                                                    {totalDealAmount} {/* Assuming dealamount is a numeric value */}
                                                                </h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="fw-bold">
                                                                    {totalMemberCommission} {/* Assuming memberCommission is a numeric value */}
                                                                </h6>
                                                            </td>
                                                            <td></td> {/* Empty cell for the "more" button column */}
                                                        </tr>

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
               
            </div >
        </>
    )
}
export default CompletedOrder;