import { React, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdReadMore } from "react-icons/md";
import Header from '../admin/Header'
import axios from 'axios'
import Swal from 'sweetalert2';

function Providerdetails() {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [data, setData] = useState(null);

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 6000);
        });
    };




    const [providers, setProviders] = useState([])
    const [subcatdata, setSubcatdata] = useState([])
    const [commission, setCommission] = useState([])
    const [Tempservice, setTempservice] = useState([])
    const id = useParams()
    const providerid = id.id
    const token = localStorage.getItem("token");


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/providerdetails/${providerid}`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data.providers, "dsdsdsd");
            setProviders(response.data.providers);
            setSubcatdata(response.data.subcatData);
            setTempservice(response.data.providers);
            setCommission(response.data.commission);

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [id]);



    var [provider_complete_order, setprovider_complete_order] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/provider_complete_order/${id.id}`, { headers: { 'token': token } }).then(function (response) {

            console.log(response.data, "33333333333333333333ddd");
            setprovider_complete_order(response.data);
            // console.log("Bussiness Category:::", bussinesscategory);


        })
            .catch(function (error) {
                console.log(error);
            })
    }, [])


    const totalMemberCommission = provider_complete_order.length > 0 ? provider_complete_order.completed.reduce((total, item) => total + parseFloat(item.memberCommission), 0) : 0;
    const totalCompanyCommission = provider_complete_order.length > 0 ? provider_complete_order.completed.reduce((total, item) => total + parseFloat(item.companyCommission), 0) : 0;
    const totaldealamount = provider_complete_order.completed && provider_complete_order.completed.length > 0
        ? provider_complete_order.completed.reduce((total, order) => {
            if (order.trackingorder && order.trackingorder.length > 0) {
                const orderTotal = order.trackingorder.reduce(
                    (orderTotal, tracking) => orderTotal + parseFloat(tracking.dealamount),
                    0
                );
                return total + orderTotal;
            }
            return total;
        }, 0)
        : 0;

    const totalcom = provider_complete_order.completed && provider_complete_order.completed.length > 0
        ? provider_complete_order.completed.reduce((total, order) => {
            if (order.trackingorder && order.trackingorder.length > 0) {
                const orderTotal = order.trackingorder.reduce(
                    (orderTotal, tracking) => orderTotal + parseFloat(tracking.providercommission),
                    0
                );
                return total + orderTotal;
            }
            return total;
        }, 0)
        : 0;


    var sss = () => { window.location.reload() }

    const [amount, setAmount] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const handleAmountChange = (value) => {
        setAmount(value);
    };

    const handleSubmit = async (e, itemId, totalcommission) => {
        e.preventDefault();

        if (amount) {
            const { value: enteredPassword } = await Swal.fire({
                title: 'Enter Password',
                html: `
              <input type="password" id="swal-input1" class="swal2-input" placeholder="Enter your password">
            `,
                focusConfirm: false,
                preConfirm: () => {
                    const password = document.getElementById('swal-input1').value;

                    if (password !== '1') {
                        Swal.showValidationMessage('Passwords do not match');
                        setIsPasswordValid(false);
                        return false;
                    }

                    setIsPasswordValid(true);
                    try {
                        const response = axios.post(`${process.env.REACT_APP_URL}/admin/providerpayment`, {
                            amount: amount,
                            itemId,
                            totalcommission,
                            providerId: providerid,
                        }, {
                            headers: { 'token': token },
                        });

                        console.log('data', response.data);
                        Swal.fire({
                            icon: 'success',
                            title: 'Submission Successful',
                            text: 'Your data has been submitted successfully.',
                        }).then(() => {
                            window.location.reload();
                        });
                        setIsSubmitting(true);
                    } catch (error) {
                        console.log(error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Submission Failed',
                            text: 'An error occurred during submission.',
                        });
                    }
                    return password;
                },
            });
        }


    };

    const XLSX = require('xlsx')
    const exportToExcel = () => {
        const headers = ['Order Id', 'Order Date', 'Customer Name', 'Order Deal Amount', 'Total Commission', 'Member Commission', 'Company Profit', 'Payment']
        const dataAsArray = filteredOrders &&
            filteredOrders.map((item, i) => [
                item.no,
                new Date(item.updatedAt).toLocaleDateString('en-GB'),
                item.otherName,
                item.trackingorder[0].dealamount,
                parseFloat(item.trackingorder[0].providercommission).toFixed(2),
                item.trackingorder[0].memberCommission,
                parseFloat(item.trackingorder[0].companyCommission).toFixed(2),
                item.trackingorder.map((tf, k) => (tf.paid ? 'Done' : 'Pending')).join(', '),
            ])

        const excelData = [headers, ...dataAsArray]
        const ws = XLSX.utils.json_to_sheet(excelData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        XLSX.writeFile(wb, 'exported_data.xlsx')
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-GB').toLowerCase();
    };

    const filteredOrders = provider_complete_order.completed
        ? provider_complete_order.completed.filter((item) => {
            const searchTermMatches =
                item.otherName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                formatDate(item.updatedAt).includes(searchTerm.toLowerCase()) ||
                item.trackingorder[0].dealamount.toLowerCase().includes(searchTerm.toLowerCase()) ||
                parseFloat(item.trackingorder[0].providercommission).toFixed(2).toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.trackingorder[0].memberCommission.toLowerCase().includes(searchTerm.toLowerCase());

            const startDateMatches = !startDateFilter || new Date(item.updatedAt) >= new Date(startDateFilter);
            const endDateMatches = !endDateFilter || new Date(item.updatedAt) <= new Date(endDateFilter);

            return searchTermMatches && startDateMatches && endDateMatches;
        })
        : [];

    const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);

    useEffect(() => {
        calculateTotalRemainingAmount();
    }, [filteredOrders]);

    const calculateTotalRemainingAmount = () => {
        let sum = 0;

        filteredOrders.forEach((item) => {
            const currentSum = isNaN(
                parseFloat(
                    (
                        parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                        parseFloat(remains(item._id)).toFixed(2)
                    ).toFixed(2)
                )
            )
                ? parseFloat(item.trackingorder[0].providercommission).toFixed(2)
                : parseFloat(
                    parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                    parseFloat(remains(item._id)).toFixed(2)
                ).toFixed(2);

            sum += parseFloat(currentSum);
        });

        setTotalRemainingAmount(sum);

    };



    function remains(itemId) {
        const matchingItems = provider_complete_order.remain.filter((item) => item.orderid === itemId);

        const amounts = matchingItems.map((item) => item.amount);

        return amounts;
    }

    function remainsDate(itemId) {
        const matchingItems = provider_complete_order.remain.filter((item) => item.orderid === itemId);

        if (matchingItems.length > 0) {
            const date = matchingItems[0].updatedAt;

            if (date !== undefined && date !== null) {
                return date;
            } else {
                return "Not Updated";
            }
        } else {
            return "Not Updated";
        }
    }


    return (
        <>
            {
                (<>
                    <Header />
                    <div>
                        <main id="main" className="main">
                            <div className="pagetitle">
                                <h1 className="text-start">Provider Details</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active">Provider</li>
                                        <li className="breadcrumb-item active">Show Provider</li>
                                    </ol>
                                </nav>
                            </div>{/* End Page Title */}
                            <section className="section dashboard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Provider Details </h5>
                                                        <div className='prodetails'>
                                                            <div className="col-12 border shadow-sm p-3 mb-2 bg-body rounded">
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-6">
                                                                        <strong>Name :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">{providers.name}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Company :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                {console.log(providers)}
                                                                                <p className="fs-6 fw-bold">{providers.Bname}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Category :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                {providers.bsubcategoryid && (
                                                                                    <p className="fs-6 fw-bold">
                                                                                        {providers.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                                    </p>
                                                                                )}

                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Number :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                {/* {console.log(providers, "p")} */}
                                                                                <p className="fs-6 fw-bold">{providers.number}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <h5 className="card-title">Pay To Company Commission </h5>
                                                        <div className='prodetails'>
                                                            <div className="col-12 border shadow-sm p-3 mb-4 bg-body rounded">
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-6">
                                                                        <strong> Completed order :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">{provider_complete_order.completed?.length}</p>

                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong> Completed order Amount :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">₹  {provider_complete_order.totalDealAmount}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Commission Pay To Company :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">₹  {provider_complete_order?.totalProviderCommission?.toFixed(2)}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Remaining Amount :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">₹  {totalRemainingAmount.toFixed(2)}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Paid Commission :</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6   fw-bold">₹  {parseFloat(parseFloat(provider_complete_order.totalProviderCommission) - parseFloat(totalRemainingAmount)).toFixed(2)}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search..."
                                                                value={searchTerm}
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className='mb-3 d-flex flex-column flex-md-row'>
                                                            <h6 className='me-md-5 mt-3'>Filter Data by Date:</h6>
                                                            <div className='d-flex'>
                                                                <span className='mt-2 me-3'>From</span>
                                                                <input
                                                                    type="date"
                                                                    className='form-control me-3'
                                                                    value={startDateFilter}
                                                                    onChange={(e) => setStartDateFilter(e.target.value)}
                                                                />
                                                                <span className='mt-2'>To</span>
                                                                <input
                                                                    type="date"
                                                                    className='form-control ms-3'
                                                                    value={endDateFilter}
                                                                    onChange={(e) => setEndDateFilter(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <h5 className="fw-bold">Number of Completed Order : {provider_complete_order.length}</h5>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <div className="hstack">
                                                                <button className="btn btn-success me-2 ms-auto mb-2" style={{ width: 'fit-content' }} onClick={exportToExcel}>Export to Excel</button>
                                                            </div>
                                                            <table className="table">
                                                                <thead className="table-primary text-center">
                                                                    <tr>
                                                                        <th>Order Id</th>
                                                                        <th>Order Date</th>
                                                                        <th>Order Deal Amount</th>
                                                                        <th>Total Commission</th>
                                                                        <th>Payment Status</th>
                                                                        <th>Last Updated</th>
                                                                        <th>Remaining Amount</th>
                                                                        <th>More</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    <>
                                                                        {filteredOrders &&
                                                                            filteredOrders.map((item, i) => {

                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{item.no}</td>
                                                                                        <td>{new Date(item.updatedAt).toLocaleDateString('en-GB')}</td>
                                                                                        <td>{item.trackingorder[0].dealamount}</td>
                                                                                        <td>{parseFloat(item.trackingorder[0].providercommission).toFixed(2)}</td>
                                                                                        <td className='w-25'>
                                                                                            {item.trackingorder.map((tf, k) => (
                                                                                                tf.paid === true ? (
                                                                                                    <span key={k} style={{ fontWeight: 'bolder' }}>&#10003;</span>
                                                                                                ) : (
                                                                                                    <div key={k} className='d-flex gap-2'>
                                                                                                        <input
                                                                                                            type='number'
                                                                                                            placeholder='Amount'
                                                                                                            className='form-control mb-2'
                                                                                                            style={{ width: 'fit-content' }}
                                                                                                            value={item.amount}
                                                                                                            onChange={(e) => handleAmountChange(e.target.value)}
                                                                                                            min={0}
                                                                                                        />
                                                                                                        <button className='btn btn-primary w-sm-100 w-md-25' style={{ width: 'fit-content' }} onClick={(e) => handleSubmit(e, item._id, item.trackingorder[0].providercommission)} disabled={isSubmitting}>
                                                                                                            Pay
                                                                                                        </button>
                                                                                                    </div>
                                                                                                )
                                                                                            ))}
                                                                                        </td>
                                                                                        <td>{new Date(remainsDate(item._id)).toLocaleDateString('en-GB')}</td>
                                                                                        <td className='w-25'>

                                                                                            {
                                                                                                isNaN(
                                                                                                    parseFloat(
                                                                                                        (
                                                                                                            parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                                                                                                            parseFloat(remains(item._id)).toFixed(2)
                                                                                                        ).toFixed(2)
                                                                                                    )
                                                                                                ) ? (
                                                                                                    <span>{parseFloat(item.trackingorder[0].providercommission).toFixed(2)}</span>
                                                                                                ) : (
                                                                                                    <span>
                                                                                                        {parseFloat(
                                                                                                            parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                                                                                                            parseFloat(remains(item._id)).toFixed(2)
                                                                                                        ).toFixed(2)}
                                                                                                    </span>
                                                                                                )
                                                                                            }


                                                                                        </td>
                                                                                        <td data-th="Net Amount" className='align-middle'>
                                                                                            <Link to={`/admin_completeorder/${item._id}`} title='View more'>
                                                                                                <MdReadMore className='fs-4 text-info' />
                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        <tr>
                                                                            <td colSpan={2}><h5 className="fw-bold"> Total: </h5></td>
                                                                            <td>
                                                                                <h6 className="fw-bold"> {totaldealamount.toFixed(2)}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6 className="fw-bold"> {totalcom.toFixed(2)}</h6>
                                                                            </td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>
                                                                                <h6 className="fw-bold"> {totalRemainingAmount.toFixed(2)}</h6>
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </main>{/* End #main */}

                    </div>
                </>)
            }




        </>
    )
}

export default Providerdetails
