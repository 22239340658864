import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ManagerHeader from './ManagerHeader';

const Manager_Notification = () => {


    const [notification, setNotification] = useState([]);
    const managertoken = localStorage.getItem("managertoken");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/manager/allnotification`, { headers: { 'managertoken': managertoken } })
            .then(function (response) {
                // hendle success
                console.log(response.data, 'get notifications');
                setNotification(response.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [])
    return (
        <>
            {
                (<>
                    <ManagerHeader />
                    <div>
                        <main id="main" className="main">
                            <div className="pagetitle">
                                <h1 className="text-start m-0" >Notification</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active">Notification</li>
                                    </ol>
                                </nav>
                            </div>{/* End Page Title */}
                           
                            <section className="section dashboard">
                                <div className="row">
                                    {/* Left side columns */}
                                    <div className="col-lg-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <label className="col-sm-2 fs-5 col-form-label mb-3 fw-bold"> Show Notification :</label>
                                                        <div className="table-responsive mt-2">
                                                            <table className="table">
                                                                <thead className="table-primary text-center">
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th>Date</th>
                                                                        <th>Sended To</th>
                                                                        <th>Notification</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    {notification.map((item, i) => (
                                                                        <tr key={item._id}>
                                                                            <td>
                                                                                {i + 1}
                                                                            </td>
                                                                            <td>
                                                                                {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                            </td>
                                                                            <td>
                                                                                {item.schema}
                                                                            </td>
                                                                            <td>
                                                                                {item.notification}
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>{/* End #main */}

                    </div>

                </>
                )}

        </>

    )
}

export default Manager_Notification