import React from "react";
import Provider_Header from "./Provider_Header";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";


const Provider_Completed = () => {

    const providertoken = localStorage.getItem("providertoken");

    const [completed, setCompleted] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/provider/completed_order`, { headers: { 'providertoken': providertoken } }).then(function (response) {
            // handle success
            console.log(response.data);
            setCompleted(response.data.completed);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    const totalCompanyCommission = completed && completed.length > 0
        ? completed.reduce((total, item) => total + parseFloat(item.trackingorder[0].providercommission), 0)
        : 0;

    const totaldealamount = completed && completed.length > 0
        ? completed.reduce((total, item) => total + parseFloat(item.trackingorder[0].dealamount), 0)
        : 0;


    return (
        <>
            <Provider_Header />

            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>Completed Order</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Orders</li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        <h5 className="fw-bold my-4">Number of orders: {completed ? completed.length : 0}</h5>
                    </div>


                    <section className="section dashboard">
                        <div className="row">
                            {/* Left side columns */}
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div class="collapse col-12" id="collapseExample">

                                        </div>
                                        <div className="card recent-sales overflow-auto">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="col-2 ms-5 me-3">
                                                    </div>
                                                    <div className="col-2">
                                                    </div>
                                                </div>
                                                <div className="table-responsive mt-2">
                                                    <table className="table text-center">
                                                        <thead className="table-primary text-center">
                                                            <tr>
                                                                <th>Order Id</th>
                                                                <th>Order Date</th>
                                                                <th>Customer Name</th>
                                                                <th>Order Service</th>
                                                                <th>Order Deal Amount</th>
                                                                <th>Company Commission</th>
                                                                <th>PayMent Status</th>
                                                                <th>Details</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-light align-middle text-center">
                                                            <>
                                                                {completed &&
                                                                    completed.map((item, i) => (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                {item.no}
                                                                            </td>

                                                                            <td>
                                                                                {item.updatedAt.slice(0, 10)}
                                                                            </td>

                                                                            <td>
                                                                                {console.log(item, "[]")}
                                                                                {item.otherName}
                                                                            </td>

                                                                            <td>
                                                                                {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                            </td>

                                                                            <td>
                                                                                {parseInt(item.trackingorder[0].dealamount).toFixed(2)}
                                                                            </td>

                                                                            <td>
                                                                                {parseInt(item.trackingorder[0].providercommission).toFixed(2)}
                                                                            </td>
                                                                            <td>
                                                                                <p>
                                                                                    <span className="fs-5">
                                                                                        {item.trackingorder[0].payment === true ? <span><i class="bi-check-circle-fill ms-4 fs-4 text-success"></i></span> : <span className="text-danger fw-bold">PayMent Panding</span>}
                                                                                    </span>
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <Link to={`/complete_orderdetails/${item._id}`}>
                                                                                    <button className="btn btn-primary">more</button>
                                                                                </Link>
                                                                            </td>

                                                                        </tr>

                                                                    ))}
                                                                <tr>
                                                                    <td colSpan={4}><h5 className="fw-bold"> Total: </h5></td>
                                                                    <td>
                                                                        <h6 className="fw-bold"> {totaldealamount.toFixed(2)}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 className="fw-bold"> {totalCompanyCommission.toFixed(2)}</h6>
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>

                                                                </tr>
                                                            </>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        </>
    )
}
export default Provider_Completed;