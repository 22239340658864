import React, { useEffect, useState } from 'react'
import Header from './Header'
import axios from 'axios';

const AddNotification = () => {

    const token = localStorage.getItem("token");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');

    const handleSelectChange = (e) => {
        setSelectedRole(e.target.value);
    };
    const [noti, setNoti] = useState('')
    const handlenoti = (e) => {
        e.preventDefault();
        
        setNoti(noti);
        const typedetails = {
            notification: noti,
            schema: selectedRole
        }

        console.log(noti);

        axios.post(`${process.env.REACT_APP_URL}/admin/addnotification`, typedetails, { headers: { 'token': token } })
            .then(function (response) {
                // hendle success
                console.log(response.data);
                setIsSubmitting(true);
                setNoti('')
                allnotification();
               
            })
            .catch(function (error) {
                console.log(error);
            })

    };
    useEffect(() => {
        allnotification();
    }, [])
    const [notification, setNotification] = useState([]);

    const allnotification = () => {
        axios.get(`${process.env.REACT_APP_URL}/admin/allnotification`, { headers: { 'token': token } })
            .then(function (response) {
                // hendle success
                console.log(response.data, 'get notifications');
                setNotification(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    return (
        <>
            {
                (<>
                    <Header />
                    <div>
                        <main id="main" className="main">
                            <div className="pagetitle">
                                <h1 className="text-start m-0" >Add Notification</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active">Notification</li>
                                    </ol>
                                </nav>
                            </div>{/* End Page Title */}
                            <section className="section dashboard">
                                <div className="row">
                                    {/* Left side columns */}
                                    <div className="col-lg-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <label className="col-sm-2 fs-5 col-form-label mb-3 fw-bold"> Add Notification :</label>
                                                        <form onSubmit={handlenoti}>
                                                            <div className="row mb-2">
                                                                <label className="col-sm-2 col-form-label fw-bold" >Send Notification To</label>
                                                                <div className="col-sm-10">
                                                                    <div className="form-floating mb-4" >
                                                                        <select className="form-control" onChange={handleSelectChange} value={selectedRole}>
                                                                            <option value="">Select</option>
                                                                            <option value="provider">Provider</option>
                                                                            <option value="member">Member</option>
                                                                            <option value="manager">Manager</option>
                                                                            <option value="all">All</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <label className="col-sm-2 col-form-label fw-bold">Notification</label>
                                                                <div className="col-sm-10">
                                                                    <div className="form-floating mb-4">
                                                                        <input type="text" className="form-control" id="floatingInput" value={noti} onChange={(e) => setNoti(e.target.value)} placeholder="Services Name" />
                                                                        <label htmlFor="floatingInput">Enter Notification </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-0 justify-content-end">
                                                                <div className="col-sm-2">
                                                                    <div className="input-group">
                                                                        <input type="submit" className="form-control bg-success text-white" disabled={isSubmitting} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section dashboard">
                                <div className="row">
                                    {/* Left side columns */}
                                    <div className="col-lg-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <label className="col-sm-2 fs-5 col-form-label mb-3 fw-bold"> Show Notification :</label>
                                                        <div className="table-responsive mt-2">
                                                            <table className="table">
                                                                <thead className="table-primary text-center">
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th>Date</th>
                                                                        <th>Sended To</th>
                                                                        <th>Notification</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    {notification.map((item, i) => (
                                                                        <tr key={item._id}>
                                                                            <td>
                                                                                {i + 1}
                                                                            </td>
                                                                            <td>
                                                                                {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                            </td>
                                                                            <td>
                                                                                {item.schema}
                                                                            </td>
                                                                            <td>
                                                                                {item.notification}
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>{/* End #main */}

                    </div>

                </>
                )}

        </>

    )
}

export default AddNotification
