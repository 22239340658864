import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useParams } from "react-router-dom";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Swal from "sweetalert2";

const Memberdetails = () => {


    //loarder
    const [isLoading, setIsLoading] = useState(true);

    const [data1, setData] = useState(null);

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 1000);
        });
    };





    //  Show Member data
    const [user, setUser] = useState([])
    const [orders, setOrders] = useState([])
    const [totalMemberCommission, setTotalMemberCommission] = useState([])
    const [remainss, setRemainss] = useState([])
    const [totalCompanyCommission, setTotalCompanyCommission] = useState([])
    const [userdata, setUserdata] = useState([])
    const id = useParams()
    const memberid = id.id
    const token = localStorage.getItem("token");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/userdetails/${memberid}`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data, "dsdsdsd")
            setUser([response.data.user]);
            setTotalCompanyCommission(response.data.totalCompanyCommission);
            setTotalMemberCommission([response.data.totalMemberCommission]);
            setTempservice(response.data.user);
            setOrders(response.data.orders);
            setRemainss(response.data.remain)
            setUserdata(response.data)

        })
            .catch(function (error) {
                // handle error
                console.log(error)
            })
    }, [memberid]);



    // Delete Member

    const nevigate = useNavigate();
    const deleteService = (e) => {
        e.preventDefault();

        axios.delete(`${process.env.REACT_APP_URL}/admin/deleteuser/${memberid}`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            // console.log(response.data, "dfdf");
            nevigate('/admin_allmember')
        })
            .catch(function (error) {
                if (error.response) {
                    alert(error.response.data.message);
                }
                else if (error.request) {
                    alert('Network error. Please try again')
                }
                else {
                    alert('An error occurred. Please try again')
                }
            });
    }




    // Update Member

    const [changed, setChanged] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tempservice, setTempservice] = useState([], { productService: [] })
    const [UpdateAddOption, setUpdateAddOption] = useState(false)
    const UpdateAdd = (e) => {
        e.preventDefault();
        setUpdateAddOption(true)
    };


    useEffect(() => {
        // console.log(tempservice,'dsdd');
    })


    const handleSubmit = (e) => {


        e.preventDefault()


        try {
            axios.patch(`${process.env.REACT_APP_URL}/admin/updateuser/${memberid}`, tempservice, {
                headers: {
                    'token': token,

                    // 'Content-Type': 'multipart/form-data',
                }
            })
                .then((response) => {
                    // console.log(response.data,"defsdd");
                    // console.log(tempservice,"dsd");
                    window.location.reload();
                    setIsSubmitting(true);
                })
                .catch(function (error) {
                    if (error.response) {
                        alert(error.response.data.message);
                    }
                    else if (error.request) {
                        alert('Network error. Please try again')
                    }
                    else {
                        alert('An error occurred. Please try again')
                    }
                });
        } catch (error) {
            console.error(error);
        }

    }

    const [amount, setAmount] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const handleAmountChange = (value) => {
        setAmount(value);
    };

    function remains(orderId) {
        const matchingItems = remainss.filter((item) => item.orderid === orderId);

        const amounts = matchingItems.map((item) => item.amount);

        return amounts;
    }

    const handleSubmitOrder = async (e, itemId, membercommission) => {

        e.preventDefault();

        if (amount) {
            const { value: enteredPassword } = await Swal.fire({
                title: 'Enter Password',
                html: `
              <input type="password" id="swal-input1" class="swal2-input" placeholder="Enter your password">
            `,
                focusConfirm: false,
                preConfirm: () => {
                    const password = document.getElementById('swal-input1').value;

                    if (password !== '1') {
                        Swal.showValidationMessage('Passwords do not match');
                        setIsPasswordValid(false);
                        return false;
                    }

                    setIsPasswordValid(true);
                    try {
                        const response = axios.post(`${process.env.REACT_APP_URL}/admin/memberpayment`, {
                            amount: amount,
                            itemId,
                            membercommission,
                            memberId: memberid,
                        }, {
                            headers: { 'token': token },
                        });

                        console.log('data', response.data);
                        Swal.fire({
                            icon: 'success',
                            title: 'Submission Successful',
                            text: 'Your data has been submitted successfully.',
                        }).then(() => {
                            window.location.reload();
                        });
                    } catch (error) {
                        console.log(error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Submission Failed',
                            text: 'An error occurred during submission.',
                        });
                    }
                    return password;
                },
            });
        }

    }

    const [totalDealAmount, setTotalDealAmount] = useState(0);
    const [totalMemberCommissions, setTotalMemberCommissions] = useState(0);
    const [totalMemberCommissionRemain, setTotalMemberCommissionRemain] = useState(0);

    useEffect(() => {
        calculateTotalDealAmount();
        calculateTotalMemberCommissions();
        calculateTotalMemberCommissionRemain();
    }, [orders]);


    const calculateTotalDealAmount = () => {
        let sum = 0;

        orders.forEach((order) => {
            const dealAmount = parseFloat(order.trackingorder[0]?.dealamount) || 0;
            sum += dealAmount;
        });

        setTotalDealAmount(sum);
    };
    const calculateTotalMemberCommissions = () => {
        let sum = 0;

        orders.forEach((order) => {
            const memberCommission = parseFloat(order.trackingorder[0]?.memberCommission) || 0;
            sum += memberCommission;
        });

        setTotalMemberCommissions(sum);
    };

    const calculateTotalMemberCommissionRemain = () => {
        let sum = 0;

        // Iterate through orders and add memberCommission to sum
        orders.forEach((order) => {
            const memberCommission = parseFloat(order.trackingorder[0]?.memberCommission) || 0;
            const adjustedCommission =
                isNaN(
                    parseFloat(
                        (parseFloat(order.trackingorder[0].memberCommission).toFixed(2) - parseFloat(remains(order._id)).toFixed(2)).toFixed(2)
                    )
                )
                    ? parseFloat(order.trackingorder[0].memberCommission).toFixed(2)
                    : parseFloat(
                        parseFloat(order.trackingorder[0].memberCommission).toFixed(2) - parseFloat(remains(order._id)).toFixed(2)
                    ).toFixed(2);

            sum += parseFloat(adjustedCommission);
        });

        setTotalMemberCommissionRemain(sum);
    };

    return (
        <>
            {
                (<>

                    <Header />
                    <main id="main" className="main">
                        <div className="pagetitle">
                            <h1 className="text-start">Member Details</h1>
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Member</li>
                                    <li className="breadcrumb-item active">Show Member</li>
                                </ol>
                            </nav>
                        </div>{/* End Page Title */}

                        <section className="section dashboard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card recent-sales overflow-auto">
                                                <div className="card-body">
                                                    <h5 className="card-title">Member Details </h5>

                                                    <div className='prodetails'>

                                                        {userdata && userdata.user && (
                                                            <div className="col-12 border shadow-sm p-3 mb-2 bg-body rounded">
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong> Name</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">{userdata.user.name}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong> Occupation</strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">{userdata.user.occupation}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-6">
                                                                        <strong>Number </strong>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="fs-6">
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">{userdata.user.number}</p>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <h5 className="card-title">Pay To Company Commission</h5>
                                                    <div className='prodetails'>
                                                        <div className="col-12 border shadow-sm p-3 mb-4 bg-body rounded">
                                                            <div className="ms-3 d-flex col-12 ">
                                                                <div className="col-6">
                                                                    <strong> Completed order</strong>
                                                                </div>
                                                                <div className="col-6">
                                                                    <span className="fs-6">
                                                                        {userdata && userdata.user && (
                                                                            <div className="pe-4 col-12 text-end">
                                                                                <p className="fs-6 fw-bold">{userdata.orders?.length}</p>
                                                                            </div>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex col-12">
                                                                <div className="col-6">
                                                                    <strong> Completed order Amount</strong>
                                                                </div>
                                                                <div className="col-6">
                                                                    <span className="fs-6">
                                                                        <div className="pe-4 col-12 text-end">
                                                                            <p className="fs-6 fw-bold">₹  {totalCompanyCommission}</p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex col-12">
                                                                <div className="col-6">
                                                                    <strong>Commission Pay To Member </strong>
                                                                </div>
                                                                <div className="col-6">
                                                                    <span className="fs-6">
                                                                        <div className="pe-4 col-12 text-end">
                                                                            <p className="fs-6 fw-bold">₹  {totalMemberCommission}</p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex col-12">
                                                                <div className="col-6">
                                                                    <strong>Remaining Amount</strong>
                                                                </div>
                                                                <div className="col-6">
                                                                    <span className="fs-6">
                                                                        <div className="pe-4 col-12 text-end">
                                                                            <p className="fs-6 fw-bold">₹  {totalMemberCommissionRemain.toFixed(2)}</p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex col-12">
                                                                <div className="col-6">
                                                                    <strong>Paid Commission</strong>
                                                                </div>
                                                                <div className="col-6">
                                                                    <span className="fs-6">
                                                                        <div className="pe-4 col-12 text-end">
                                                                            <p className="fs-6 fw-bold">₹  {parseFloat(parseFloat(totalMemberCommission) - parseFloat(totalMemberCommissionRemain)).toFixed(2)}</p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="pe-4 col-12 text-end">
                            <Link><button onClick={deleteService} className="btn btn-danger px-5 me-2 w-25 mb-3">Delete</button></Link>

                            <Link><button onClick={UpdateAdd} id="updateButton" className="btn btn-primary w-25 me-1 px-5 mb-3">Update</button></Link>
                        </div>
                        {user.map((item, i) => (
                            <div className='prodetails'>
                                <div className="col-12 border shadow-sm p-3 mb-5 bg-body rounded">
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member ID :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'ids' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member Name :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'name' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member Email :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'email' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member Number :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'number' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member D.O.B. :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'DOB' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member Occupation :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'occupation' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Member address :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'address' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12 ">
                                        <div className="col-4">
                                            <p><strong>Reference Name :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'reference' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12">
                                        <div className="col-4">
                                            <p><strong>Reference Number :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'ref_no' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12">
                                        <div className="col-4">
                                            <p><strong>Bank Name :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'bankname' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12">
                                        <div className="col-4">
                                            <p><strong>Bank Account :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'bankaccount' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12">
                                        <div className="col-4">
                                            <p><strong>Bank IFSC :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'bankifsc' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12">
                                        <div className="col-4">
                                            <p><strong>Bank Branch :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'bankbranch' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-3 d-flex col-12">
                                        <div className="col-4">
                                            <p><strong>UPI ID :</strong></p>
                                        </div>
                                        <div className="col-8">
                                            <p>
                                                <span className="fs-6">
                                                    {
                                                        Object.entries(item).map(([key, value], index) => {
                                                            return (
                                                                <>{key == 'upiid' ? (<>
                                                                    {value}
                                                                </>) : null}

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <section>

                            {
                                UpdateAddOption ? (
                                    <>
                                        <div className="row">
                                            {/* Provider Data */}
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card recent-sales overflow-auto">
                                                            <div className="card-body">
                                                                <h5 className="card-title">Member Update</h5>
                                                                <div>
                                                                    <form encType='multipart/formData'>
                                                                        <div className='card recent-sales overflow-auto'>
                                                                            <div className='card-body'>
                                                                                <div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member ID <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.ids} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, ids: e.target.value, }) }} placeholder='Provider Name' />
                                                                                                <label htmlFor="floatingTextarea">Member ID</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Name <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.name} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, name: e.target.value, }) }} placeholder='Provider Name' />
                                                                                                <label htmlFor="floatingTextarea">Member Name</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Email <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='Email' className='form-control' required value={tempservice.email} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, email: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Number</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Numbar <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='email' className='form-control' required value={tempservice.number} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, number: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Number</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member DOB <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='date' className='form-control' required value={tempservice.DOB} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, DOB: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member DOB</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Occupation <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.occupation} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, occupation: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Occupation </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Address <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.address} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, address: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Occupation </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Reference Name <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.reference} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, reference: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Reference Name </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Reference Number <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='number' className='form-control' required value={tempservice.ref_no} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, ref_no: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Reference Number </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank Name <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.bankname} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankname: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank Name</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank Account <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='number' className='form-control' required value={tempservice.bankaccount} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankaccount: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank Account</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank IFSC <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.bankifsc} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankifsc: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank IFSC</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank Branch <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.bankbranch} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankbranch: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank Branch</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>UPI ID <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.upiid} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, upiid: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">UPI ID</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-5 justify-content-center">
                                                                            <div className="col-sm-4">
                                                                                <div className="input-group mb-3">
                                                                                    {changed ? (
                                                                                        <>
                                                                                            <div>
                                                                                                <button onClick={(e) => { setTempservice({ ...user }); setChanged(false); }} className="btn bg-primary-subtle me-3">Cancel</button>
                                                                                            </div>
                                                                                            <div>
                                                                                                <button onClick={handleSubmit} className="btn bg-primary-subtle">Update</button>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </>
                                ) : (<></>)
                            }
                        </section>

                        <section className="section dashboard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card recent-sales overflow-auto">
                                                <div className="card-body">
                                                    <h5>Member Commission </h5>
                                                    <div className='prodetails'>
                                                        {/* <div className="col-12 border shadow-sm p-3 mb-5 bg-body rounded">
                                                            <div className="ms-3 d-flex col-12 ">
                                                                <div className="col-4">
                                                                    <strong className="fs-4">Member Commission</strong>
                                                                </div>
                                                                <div className="col-8">
                                                                    <span className="fs-6">
                                                                        <div className="pe-4 col-12 text-end">
                                                                            <p className="fs-4 fw-bold">₹ {totalMemberCommission}</p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div> */}
                                                        <div className="col-12">
                                                            <table className="table">
                                                                <thead className="table-primary text-center">
                                                                    <tr>
                                                                        <th>Order Id</th>
                                                                        <th>Order Date</th>
                                                                        <th>Order Deal Amount</th>
                                                                        <th>Member Commission</th>
                                                                        <th>Provider Payment Status</th>
                                                                        <th>Member Payment</th>
                                                                        <th>Last Updated</th>
                                                                        <th>Remaining Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    {orders.map((order) => (
                                                                        <>
                                                                            <tr key={order._id}>
                                                                                <td>{order.no}</td>
                                                                                <td>{new Date(order.createdAt).toLocaleDateString('en-GB')}</td>
                                                                                <td>{parseFloat(order.trackingorder[0]?.dealamount).toFixed(2)}</td>
                                                                                <td>{parseFloat(order.trackingorder[0]?.memberCommission).toFixed(2)}</td>
                                                                                <td>{order.trackingorder[0]?.paid === true ? 'Paid' : 'Unpaid'}</td>
                                                                                <td className='w-25'>
                                                                                    {order.trackingorder.map((tf, k) => (
                                                                                        tf.memberpaid === true ? (
                                                                                            <span key={k} style={{ fontWeight: 'bolder' }}>&#10003;</span>
                                                                                        ) : (
                                                                                            <div key={k} className='d-flex gap-2'>
                                                                                                <input
                                                                                                    type='number'
                                                                                                    placeholder='Amount'
                                                                                                    className='form-control'
                                                                                                    style={{ width: 'fit-content' }}
                                                                                                    value={order.amount}
                                                                                                    min={0}
                                                                                                    onChange={(e) => handleAmountChange(e.target.value)}
                                                                                                />
                                                                                                <button className='btn btn-primary w-sm-100 w-md-25' onClick={(e) => handleSubmitOrder(e, order._id, order.trackingorder[0].memberCommission)}>
                                                                                                    Pay
                                                                                                </button>
                                                                                            </div>
                                                                                        )
                                                                                    ))}
                                                                                </td>
                                                                                <td>{new Date(order.updatedAt).toLocaleDateString('en-GB')}</td>
                                                                                <td className='w-25'>
                                                                                    {order.trackingorder[0]?.memberCommission !== undefined ? (
                                                                                        isNaN(
                                                                                            parseFloat(
                                                                                                (
                                                                                                    parseFloat(order.trackingorder[0].memberCommission).toFixed(2) -
                                                                                                    parseFloat(remains(order._id)).toFixed(2)
                                                                                                ).toFixed(2)
                                                                                            )
                                                                                        ) ? (
                                                                                            <span>{parseFloat(order.trackingorder[0].memberCommission).toFixed(2)}</span>
                                                                                        ) : (
                                                                                            <span>
                                                                                                {parseFloat(
                                                                                                    parseFloat(order.trackingorder[0].memberCommission).toFixed(2) -
                                                                                                    parseFloat(remains(order._id)).toFixed(2)
                                                                                                ).toFixed(2)}
                                                                                            </span>
                                                                                        )
                                                                                    ) : (
                                                                                        <span>Member Commission not available</span>
                                                                                    )}
                                                                                </td>


                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                    <tr>
                                                                        <td colSpan={2}><h5 className="fw-bold"> Total: </h5></td>
                                                                        <td>
                                                                            <h6 className="fw-bold"> {totalDealAmount.toFixed(2)}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6 className="fw-bold"> {totalMemberCommissions.toFixed(2)}</h6>
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td>
                                                                            <h6 className="fw-bold"> {totalMemberCommissionRemain.toFixed(2)}</h6>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>

                            {
                                UpdateAddOption ? (
                                    <>
                                        <div className="row">
                                            {/* Provider Data */}
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card recent-sales overflow-auto">
                                                            <div className="card-body">
                                                                <h5>Member Update</h5>
                                                                <div>
                                                                    <form encType='multipart/formData'>
                                                                        <div className='card recent-sales overflow-auto'>
                                                                            <div className='card-body'>
                                                                                <div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member ID <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.ids} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, ids: e.target.value, }) }} placeholder='Provider Name' />
                                                                                                <label htmlFor="floatingTextarea">Member ID</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Name <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.name} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, name: e.target.value, }) }} placeholder='Provider Name' />
                                                                                                <label htmlFor="floatingTextarea">Member Name</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Email <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='Email' className='form-control' required value={tempservice.email} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, email: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Number</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Numbar <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='email' className='form-control' required value={tempservice.number} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, number: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Number</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member DOB <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='date' className='form-control' required value={tempservice.DOB} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, DOB: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member DOB</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Occupation <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.occupation} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, occupation: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Occupation </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Member Address <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.address} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, address: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Member Occupation </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Reference Name <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.reference} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, reference: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Reference Name </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Reference Number <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='number' className='form-control' required value={tempservice.ref_no} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, ref_no: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Reference Number </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank Name <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.bankname} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankname: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank Name</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank Account <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='number' className='form-control' required value={tempservice.bankaccount} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankaccount: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank Account</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank IFSC <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.bankifsc} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankifsc: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank IFSC</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>Bank Branch <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.bankbranch} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, bankbranch: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">Bank Branch</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row mb-5'>
                                                                                        <label className='col-sm-3 col-lg-2 col-form-lable fw-bold'>UPI ID <span className='text-red'>*</span></label>
                                                                                        <div className='col-sm-9 col-lg-10'>
                                                                                            <div className='me-3 form-floating'>
                                                                                                <input type='text' className='form-control' required value={tempservice.upiid} onChange={(e) => { setChanged(true); setTempservice({ ...tempservice, upiid: e.target.value, }) }} placeholder='Provider Numbar' />
                                                                                                <label htmlFor="floatingTextarea">UPI ID</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-5 justify-content-center">
                                                                            <div className="col-sm-4">
                                                                                <div className="input-group mb-3">
                                                                                    {changed ? (
                                                                                        <>
                                                                                            <div>
                                                                                                <button onClick={(e) => { setTempservice({ ...user }); setChanged(false); }} className="btn bg-primary-subtle me-3">Cancel</button>
                                                                                            </div>
                                                                                            <div>
                                                                                                <button onClick={handleSubmit} className="btn bg-primary-subtle" disabled={isSubmitting}>Update</button>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </>
                                ) : (<></>)
                            }
                        </section>

                    </main>
                </>)
            }
        </>
    )

}
export default Memberdetails