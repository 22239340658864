import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Header from "../admin/Header";
import { Link } from 'react-router-dom';


const DashboardDoneOrder = () => {


    // // loader
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState(null);

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 1000);
        });
    };



    const token = localStorage.getItem("token");
    const [orders, setOrders] = useState('');
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/showdeleteorder`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data, "oiu");
            setOrders(response.data);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])


    const XLSX = require('xlsx');
    const exportToExcel = () => {
        const headers = ['Order Id', 'Order Date', 'Member Name', 'Provider Name', 'Order Service', 'Order Deal Amount', 'Member Commission', 'Company Commission'];

        // Fetch data from the API and store it in the 'data' variable
        const dataAsArray = orders.map((item) => [
            item.no || '', // Added default value for 'no'
            item.updatedAt?.slice(0, 10) || '',
            item.userid?.name || '',
            (item.providerid && item.providerid[0]?.name) || '', // Added null check for 'providerid'
            item.productid?.bsubcategoryid?.[0]?.bussinesssubcategory || '',
            item.dealamount || '',
            item.memberCommission || '',
            item.companyCommission || '',
        ]);

        const excelData = [headers, ...dataAsArray];
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'exported_data.xlsx');
    };

    const [searchInput, setSearchInput] = useState('')
    const [filteredData, setFilteredData] = useState([])
    // Assuming your initial data is stored in the `adminorder` state
    // const [adminorder, setAdminOrder] = useState([]);

    useEffect(() => {
        // Filter the data based on the search input
        // console.log(orders.length,"o")
        if (searchInput.length == 0) {
            setOrders(orders);
        }
        if (orders.length == 0) {
            setOrders(orders);
        } else {
            // console.log(orders);
            const filtered = orders.filter((item) => {
                // console.log(item.userid.name, "p[",orders);
                return (
                    // item.orderid.declineproviderid[0].name.toLowerCase().includes(searchInput.toLowerCase()) 

                    (typeof item.userid.name === 'string' && item.userid.name.toLowerCase().includes(searchInput)) ||
                    // (item.orderid.no.toString().includes(searchInput.toLowerCase())) ||  // Convert to string
                    item.otherName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    // item.orderid.productid.bsubcategoryid[0].bcategoryid.bussinesscategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                    // item.orderid.productid.bsubcategoryid[0].bussinesssubcategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.productid.product.toLowerCase().includes(searchInput.toLowerCase())
                )
            })

            setFilteredData(filtered)
            console.log(searchInput);
        }
    }, [searchInput, orders])

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value)
    };

    return (
        <>
            {
                (<>
                    <Header />
                    <main id="main" className='main'>
                        <div className='pagetitle'>
                            <h1 className='text-start m-0'>Deleted Order</h1>
                            <nav>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item'>Home</li>
                                    <li className='breadcrumb-item activ'>Deleted Order</li>
                                </ol>
                            </nav>
                        </div>
                        <section className="section dashboard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="collapse col-12" id="collapseExample">
                                            </div>
                                            <div className="card recent-sales overflow-auto">
                                                <div className="card-body">
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2"
                                                            placeholder="Search Term"
                                                            value={searchInput}
                                                            onChange={handleSearchInputChange}
                                                        />
                                                    </div>
                                                    <div>
                                                        <h5 className="fw-bold">number of Deleted Order : {orders.length}</h5>
                                                    </div>
                                                    <div className="d-flex mb-3">
                                                        <div className="">
                                                            <button className="btn btn-success" onClick={exportToExcel}>Export Excel</button>
                                                        </div>
                                                        <div className="col-2 ms-5 me-3">
                                                        </div>
                                                        <div className="col-2">
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive mt-2">
                                                        <table className="table">
                                                            <thead className="table-primary text-center">
                                                                <tr>
                                                                    <th>Order Id</th>
                                                                    <th>Order Date</th>
                                                                    <th>Mamber Name</th>
                                                                    <th>Provider Name</th>
                                                                    <th>Order Service</th>
                                                                    <th>Order Deal Amount</th>
                                                                    <th>Mamber Commission</th>
                                                                    <th>Company Commission</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="table-light text-center">
                                                                <>
                                                                    {/* {console.log(orders, "p{")} */}
                                                                    {filteredData &&
                                                                        filteredData.map((item, i) => (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {item.no}
                                                                                </td>

                                                                                <td>
                                                                                    {item.updatedAt.slice(0, 10)}
                                                                                </td>

                                                                                <td>
                                                                                    {item.userid.name}
                                                                                </td>

                                                                                <td>
                                                                                    {item.providerid.map(provider => provider.name).join(', ')}
                                                                                </td>

                                                                                <td>
                                                                                    {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                                </td>

                                                                                <td>
                                                                                    {item.dealamount}
                                                                                </td>

                                                                                <td>
                                                                                    {item.memberCommission}
                                                                                </td>

                                                                                <td>
                                                                                    {item.companyCommission}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                </>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                </>)}
        </>
    )
}
export default DashboardDoneOrder;