// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main{
    margin-top: 0;
    box-shadow: 2px 1px 2px rgba(202, 202, 202, 0.8);
}
/* .main .navbar .container-fluid .profile .profile-img{
width: 50px;
height: 50px;
border: 3px solid rgb(10, 10, 10);
border-radius: 50%;
margin-left: 10px;
} */
.main .navbar .container-fluid .profile .profile-img img{
    width: 50px;
    height: 50px;
    border: 3px solid rgb(139, 198, 253);
    border-radius: 50%;
    margin-left: 10px;
object-fit: cover;
}



.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: end !important;
}




.nav{
    width: -moz-fit-content;
    width: fit-content;
    height: 100vh;
    margin-top: 0;
    box-shadow: 2px 1px 2px rgba(202, 202, 202, 0.8);
}

.navbar-expand-lg .navbar-nav {
    flex-direction:column !important;
}

.navbar-expand-lg .navbar-nav .nav-item .active{
    color:rgb(0, 0, 0);
    border-radius: 10px;
    background-color: #c4e8ff;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/Home.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,gDAAgD;AACpD;AACA;;;;;;GAMG;AACH;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,iBAAiB;AACrB,iBAAiB;AACjB;;;;AAIA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,+BAA+B;AACnC;;;;;AAKA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,gDAAgD;AACpD;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n.main{\n    margin-top: 0;\n    box-shadow: 2px 1px 2px rgba(202, 202, 202, 0.8);\n}\n/* .main .navbar .container-fluid .profile .profile-img{\nwidth: 50px;\nheight: 50px;\nborder: 3px solid rgb(10, 10, 10);\nborder-radius: 50%;\nmargin-left: 10px;\n} */\n.main .navbar .container-fluid .profile .profile-img img{\n    width: 50px;\n    height: 50px;\n    border: 3px solid rgb(139, 198, 253);\n    border-radius: 50%;\n    margin-left: 10px;\nobject-fit: cover;\n}\n\n\n\n.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {\n    display: flex;\n    flex-wrap: inherit;\n    align-items: center;\n    justify-content: end !important;\n}\n\n\n\n\n.nav{\n    width: fit-content;\n    height: 100vh;\n    margin-top: 0;\n    box-shadow: 2px 1px 2px rgba(202, 202, 202, 0.8);\n}\n\n.navbar-expand-lg .navbar-nav {\n    flex-direction:column !important;\n}\n\n.navbar-expand-lg .navbar-nav .nav-item .active{\n    color:rgb(0, 0, 0);\n    border-radius: 10px;\n    background-color: #c4e8ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
