// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cascading-right {
    margin-right: -50px;
}

@media (max-width: 991.98px) {
    .cascading-right {
        margin-right: 0;
    }
}

.mm {
    background: hsla(0, 0%, 100%, 0.55);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);

}

.sidebar.sidebar {
    position: fixed;
    top: 0;
    left: -300px; 
    width: 300px;
    height: 100vh;
    transition: left 0.3s ease;
  }
  
  .sidebar.sidebar-open {
    left: 0; 
  }
  .sidebar.sidebar-close {
    left: 0; 
  }

  .a-none{
    text-decoration: none !important;
  }

   `, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,mCAAmC;IACnC,mCAA2B;YAA3B,2BAA2B;;AAE/B;;AAEA;IACI,eAAe;IACf,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,0BAA0B;EAC5B;;EAEA;IACE,OAAO;EACT;EACA;IACE,OAAO;EACT;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":[".cascading-right {\n    margin-right: -50px;\n}\n\n@media (max-width: 991.98px) {\n    .cascading-right {\n        margin-right: 0;\n    }\n}\n\n.mm {\n    background: hsla(0, 0%, 100%, 0.55);\n    backdrop-filter: blur(30px);\n\n}\n\n.sidebar.sidebar {\n    position: fixed;\n    top: 0;\n    left: -300px; \n    width: 300px;\n    height: 100vh;\n    transition: left 0.3s ease;\n  }\n  \n  .sidebar.sidebar-open {\n    left: 0; \n  }\n  .sidebar.sidebar-close {\n    left: 0; \n  }\n\n  .a-none{\n    text-decoration: none !important;\n  }\n\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
