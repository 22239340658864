import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Reset_Password = () => {


  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('')
  const [cpassword, setCpassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nevigate = useNavigate()


  const handleSubmit = (e) => {
    e.preventDefault();
    const email = localStorage.getItem('Email');
    setIsSubmitting(true);
    if (password === cpassword) {
      axios.post(`${process.env.REACT_APP_URL}/verifyotp`, { otp, password, cpassword, email })

        .then(function (response) {
          if (response.data.success === true) {
            nevigate('/', { replace: true })
            localStorage.removeItem('Email')
          }
        }).catch(function (error) {
          if (error.response) {
            alert(error.response.data.message);
          } else if (error.request) {
            alert('Network error. Please try again later.');
          } else {
            alert('An error occurred. Please try again later.');
          }
        }).finally(() => {
          setIsSubmitting(false);
        });

    } else {
      alert('Password should Match!')
    }
  }

  return (
    <>
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center
      min-vh-100 g-0">
          <div class="col-12 col-md-8 col-lg-4 border-top border-3 border-primary">
            <div class="card shadow-sm">
              <div class="card-body">
                <img src={require('../img/Logos.png')} style={{ height: '80px' }} alt="Logo" className="img-fluid mb-4" />
                
                <form onSubmit={handleSubmit}>
                  <div class="mb-3">
                    <label for="password" class="form-label">New Password</label>
                    <input type="text" id="password" value={password} onChange={(e) => setPassword(e.target.value)} class="form-control mb-2" name="Password" placeholder="Enter Your New Password"
                      required="" />
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label"> ConfirmPassword</label>
                    <input type="password" id="password1" value={cpassword} onChange={(e) => setCpassword(e.target.value)} class="form-control" name="Password" placeholder="Enter Your Confirm  Password"
                      required="" />
                  </div>
                  <div className="input-group input-group-outline my-3">
                    <input type="number" placeholder="Enter Otp" className="form-control" value={otp} onChange={(e) => setOtp(e.target.value)} />
                  </div>
                  <div class="mb-3 d-grid">
                    <button type="submit" class="btn btn-primary" disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                  <span>Already have an account? <a href="/">Sign in</a></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )

}
export default Reset_Password;