import React from "react";
import Provider_Header from "./Provider_Header";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";


const Provider_Completed = () => {

    const providertoken = localStorage.getItem("providertoken");

    const [canceled, setCanceled] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/provider/completed_order`, { headers: { 'providertoken': providertoken } }).then(function (response) {
            // handle success
            console.log(response.data);
            setCanceled(response.data.canceled);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    return (
        <>
            <Provider_Header />

            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>Cancel Order</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Orders</li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        <h5 className="fw-bold my-4">Number of orders : {canceled.length}</h5>
                    </div>

                    <section className="section dashboard">
                        <div className="row">
                            {/* Left side columns */}
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div class="collapse col-12" id="collapseExample">

                                        </div>
                                        <div className="card recent-sales overflow-auto">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="col-2 ms-5 me-3">
                                                    </div>
                                                    <div className="col-2">
                                                    </div>
                                                </div>

                                                <div className="table-responsive mt-2">
                                                    <table className="table">
                                                        <thead className="table-primary text-center">

                                                            <tr>
                                                                <th>Order Id</th>
                                                                <th>Order Date</th>
                                                                <th>Mamber Name</th>
                                                                <th>Order Service</th>
                                                                <th>Order Deal Amount</th>
                                                                <th>Company Commission</th>
                                                                <th>Payment Status</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-light text-center">
                                                            <>
                                                                {canceled &&
                                                                    canceled.map((item, i) => (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                {item.no}
                                                                            </td>
                                                                            <td>
                                                                                {item.updatedAt.slice(0, 10)}
                                                                            </td>
                                                                            <td>
                                                                                {item.userid.name}
                                                                            </td>
                                                                            <td>
                                                                                {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                            </td>
                                                                            <td>
                                                                                0
                                                                            </td>
                                                                            <td>
                                                                                0
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-danger fw-bold">Cancel Order</span>
                                                                            </td>

                                                                        </tr>

                                                                    ))}
                                                            </>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        </>
    )
}
export default Provider_Completed;