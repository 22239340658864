import React from "react";
import Provider_Header from "./Provider_Header";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";


const Provider_Payment = () => {

    const providertoken = localStorage.getItem("providertoken");
    const [alldata, setAlldata] = useState('')
    const [completed, setCompleted] = useState('');
    const [remainss, setRemainss] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/provider/completed_order`, { headers: { 'providertoken': providertoken } }).then(function (response) {
            // handle success
            console.log(response.data, 'dssdf');
            setAlldata(response.data);
            setRemainss(response.data.remain)
            setCompleted(response.data.completed);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    function remains(itemId) {
        const matchingItems = remainss.filter((item) => item.orderid === itemId);

        const amounts = matchingItems.map((item) => item.amount);

        return amounts;
    }

    const totalCompanyCommission = completed.length > 0 ? completed.reduce((total, item) => total + parseFloat(item.trackingorder[0].providercommission), 0) : 0;
    const totaldealamount = completed.length > 0 ? completed.reduce((total, item) => total + parseFloat(item.trackingorder[0].dealamount), 0) : 0;

    const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);

    useEffect(() => {
        calculateTotalRemainingAmount();
    }, [alldata]);

    const calculateTotalRemainingAmount = () => {
        if (Array.isArray(completed)) {
            let sum = 0;

            completed.forEach((item) => {
                const currentSum = isNaN(
                    parseFloat(
                        (
                            parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                            parseFloat(remains(item._id)).toFixed(2)
                        ).toFixed(2)
                    )
                )
                    ? parseFloat(item.trackingorder[0].providercommission).toFixed(2)
                    : parseFloat(
                        parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                        parseFloat(remains(item._id)).toFixed(2)
                    ).toFixed(2);

                sum += parseFloat(currentSum);
            });

            setTotalRemainingAmount(sum);
        } else {
            console.error("Completed is not an array");
        }

    };

    return (
        <>
            <Provider_Header />

            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>Payment Detail</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Orders</li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        <h5 className="fw-bold my-4">Number of orders : {completed.length}</h5>
                    </div>

                    <section className="section dashboard">
                        <div className="row">
                            {/* Left side columns */}
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div class="collapse col-12" id="collapseExample">

                                        </div>
                                        <div className="card recent-sales overflow-auto">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="col-2 ms-5 me-3">
                                                    </div>
                                                    <div className="col-2">
                                                    </div>
                                                </div>
                                                <div className="table-responsive mt-2">
                                                    <table className="table text-center">
                                                        <thead className="table-primary">
                                                            <tr>
                                                                <th>Order Id</th>
                                                                <th>Order Date</th>
                                                                <th>Customer Name</th>
                                                                <th>Order Service</th>
                                                                <th>Order Deal Amount</th>
                                                                <th>Company Commission</th>
                                                                <th>Payment Status</th>
                                                                <th>Details</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-light align-middle">
                                                            <>
                                                                {completed &&
                                                                    completed.map((item, i) => {

                                                                        return (

                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {item.no}
                                                                                </td>

                                                                                <td>
                                                                                    {item.updatedAt.slice(0, 10)}
                                                                                </td>

                                                                                <td>
                                                                                    {/* {console.log(item, "[]")} */}
                                                                                    {item.otherName}
                                                                                </td>

                                                                                <td>
                                                                                    {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                                </td>

                                                                                <td>
                                                                                    {parseFloat(item.trackingorder[0].dealamount).toFixed(2)}
                                                                                </td>

                                                                                <td>
                                                                                    {parseFloat(item.trackingorder[0].providercommission).toFixed(2)}
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fs-6">
                                                                                        {item.trackingorder[0].paid === true ? <span><i class="bi-check-circle-fill fs-4 text-success"></i></span> :
                                                                                            isNaN(
                                                                                                parseFloat(
                                                                                                    (
                                                                                                        parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                                                                                                        parseFloat(remains(item._id)).toFixed(2)
                                                                                                    ).toFixed(2)
                                                                                                )
                                                                                            ) ? (
                                                                                                <span>{parseFloat(item.trackingorder[0].providercommission).toFixed(2)}</span>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    {parseFloat(
                                                                                                        parseFloat(item.trackingorder[0].providercommission).toFixed(2) -
                                                                                                        parseFloat(remains(item._id)).toFixed(2)
                                                                                                    ).toFixed(2)}
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <Link to={`/complete_orderdetails/${item._id}`}>
                                                                                        <button className="btn btn-primary">more</button>
                                                                                    </Link>
                                                                                </td>

                                                                            </tr>

                                                                        )
                                                                    })}
                                                                <tr>
                                                                    <td colSpan={4}><h5 className="fw-bold"> Total: </h5></td>
                                                                    <td>
                                                                        <h6 className="fw-bold"> {totaldealamount.toFixed(2)}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 className="fw-bold"> {totalCompanyCommission.toFixed(2)}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 className="fw-bold" id="remaining"> {totalRemainingAmount.toFixed(2)}</h6>
                                                                    </td>
                                                                    <td></td>

                                                                </tr>
                                                            </>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        </>
    )
}
export default Provider_Payment;