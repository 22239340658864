import React from "react";
import Header from "../admin/Header";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Loader from "../admin/Loader";

const DashboardMamberCommission = () => {

    // loader
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState(null);

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 1000);
        });
    };



    const [commissionTotals, setCommissionTotals] = useState('')
    const token = localStorage.getItem("token");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/comission_total`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data, 'members');
            setCommissionTotals(response.data.commissionTotals)
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])
    return (
        <>
            {
                (<>
                    <Header />

                    <main id="main" className='main'>
                        <div className='pagetitle'>
                            <h1 className='text-start m-0'>Member Commission</h1>
                            <nav>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item'>Home</li>
                                    <li className='breadcrumb-item activ'>Member Commission</li>
                                </ol>
                            </nav>
                        </div>

                        <section className="section dashboard">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="table-responsive">
                                        <table className="table text-center">
                                            <thead>
                                                <tr>
                                                    <th>Member Name</th>
                                                    <th>Total Orders</th>
                                                    <th>Total Member Commission</th>
                                                    <th>Total Company Commission</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(commissionTotals).map((key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <a href={`/admin_memberordershow/${commissionTotals[key].userId}`} className="a-none">
                                                                {commissionTotals[key].userName}
                                                            </a>
                                                        </td>
                                                        <td>{commissionTotals[key].totalOrders}</td>
                                                        <td>{commissionTotals[key].totalMemberCommission}</td>
                                                        <td>{commissionTotals[key].totalCompanyCommission}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </main>
                  
                </>)}
        </>
    )
}
export default DashboardMamberCommission;