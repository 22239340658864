import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../admin/Header";

const DashboardCancelOrderShow = () => {

    const token = localStorage.getItem("token");
    const [cancel, setCancel] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/cancel_order`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data, "Order:::");
            setCancel(response.data.cancel);

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    const XLSX = require('xlsx');
    const exportToExcel = () => {
        const headers = ['Member ID', 'Member Name', 'Member Number', 'Category', 'Sub Category', 'Product and Service', 'Description'];

        const dataAsArray = cancel.map((item) => {
            const no = item.orderid?.no || '';
            const otherName = item.orderid?.otherName || '';
            const otherNumber = item.orderid?.otherNumber || '';
            const bcategory = (item.orderid?.bsubcategoryid?.[0]?.bcategoryid?.bussinesscategory) || '';
            const bsubcategory = (item.orderid?.bsubcategoryid?.[0]?.bussinesssubcategory) || '';
            const product = item.orderid?.productid?.product || '';
            const description = item.orderid?.description || '';

            return [no, otherName, otherNumber, bcategory, bsubcategory, product, description];
        });

        const excelData = [headers, ...dataAsArray];
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'exported_data.xlsx');
        // deepF
    };


    const [searchInput, setSearchInput] = useState('')
    const [filteredData, setFilteredData] = useState([])
    // Assuming your initial data is stored in the `adminorder` state
    // const [adminorder, setAdminOrder] = useState([]);

    useEffect(() => {
        // Filter the data based on the search input
        // console.log(orders.length,"o")
        if (searchInput.length == 0) {
            setCancel(cancel);
        }
        if (cancel.length == 0) {
            setCancel(cancel);
        } else {
            // console.log(orders);
            const filtered = cancel.filter((item) => {
                console.log(item, "p[");
                return (
                    // item.orderid.declineproviderid[0].name.toLowerCase().includes(searchInput.toLowerCase()) 

                    (typeof item.orderid.userid.number === 'string' && item.orderid.userid.number.includes(searchInput)) ||
                    (item.orderid.no.toString().includes(searchInput.toLowerCase())) ||  // Convert to string
                    item.orderid.otherName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.orderid.productid.bsubcategoryid[0].bcategoryid.bussinesscategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.orderid.productid.bsubcategoryid[0].bussinesssubcategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.orderid.productid.product.toLowerCase().includes(searchInput.toLowerCase())
                )
            })

            setFilteredData(filtered)
            console.log(searchInput);
        }
    }, [searchInput, cancel])

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value)
    };
    return (
        <>

            <Header />


            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>All Cancel Order</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Show Order</li>
                            </ol>
                        </nav>
                    </div>

                    <section className="section dashboard">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">

                                    <div class="card card-body">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                placeholder="Search Term"
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div>
                                            <h5 className="fw-bold">Number of cancel Order : {cancel.length}</h5>
                                        </div>
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <button className="btn btn-success" style={{ width: 'fit-content' }} onClick={exportToExcel}>Export Excel</button>
                                            </div>

                                        </div>
                                        <div className="table-responsive mt-2">
                                            <table className="table">
                                                <thead className="table-primary text-center">
                                                    <tr>
                                                        <th>Order Id</th>
                                                        <th>Provider Id</th>
                                                        <th>Date</th>
                                                        <th>Customer Name</th>
                                                        <th>Customer Number</th>
                                                        <th>Category</th>
                                                        <th>Sub Category</th>
                                                        <th>Product and Service</th>
                                                        <th>Description</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-light text-center">
                                                    {filteredData &&
                                                        filteredData.map((item, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    {item.orderid.no}
                                                                </td>
                                                                <td>
                                                                    {item.orderid.declineproviderid[0].ids}
                                                                </td>
                                                                <td>
                                                                    {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                </td>
                                                                <td>
                                                                    {item.orderid.otherName}
                                                                </td>
                                                                <td>
                                                                    {item.orderid.otherNumber}
                                                                </td>

                                                                <td>
                                                                    {item.orderid.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                </td>
                                                                <td>
                                                                    {item.orderid.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                </td>
                                                                <td>
                                                                    {item.orderid.productid.product}
                                                                </td>
                                                                <td>
                                                                    {item.orderid.description}
                                                                </td>
                                                                <td>
                                                                    <Link to={`/admin_orderdetails/${item.orderid._id}`}>
                                                                        <button className="btn btn-primary">more</button>
                                                                    </Link>
                                                                </td>
                                                                {/* <td data-th="Net Amount">
                                                        <Link to={`/manager_acceptdetails/${item._id}`}>
                                                        <button type="button" className="btn btn-primary btn-sm">Show</button>
                                                        </Link>
                                                    </td> */}
                                                            </tr>

                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div >
        </>
    )
}
export default DashboardCancelOrderShow;