import React, { useEffect, useState } from 'react'
import Header from './Header'
import axios from 'axios';

const Suggestion = () => {

    const token = localStorage.getItem("token");

    const [notification, setNotification] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/allsug`, { headers: { 'token': token } })
            .then(function (response) {
                // hendle success
                console.log(response.data, 'get suggestion');
                setNotification(response.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [])
    return (
        <>
            {
                (<>
                    <Header />
                    <div>
                        <main id="main" className="main">
                            <div className="pagetitle">
                                <h1 className="text-start m-0" >Suggestion & Complaints</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active">Suggestion and Complaints</li>
                                    </ol>
                                </nav>
                            </div>{/* End Page Title */}

                            <section className="section dashboard">
                                <div className="row">
                                    {/* Left side columns */}
                                    <div className="col-lg-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <label className="col-sm-6 fs-5 col-form-label mb-3 fw-bold"> Show Suggestions & Complaints :</label>
                                                        <div className="table-responsive mt-2">
                                                            <table className="table">
                                                                <thead className="table-primary text-center">
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th>Date</th>
                                                                        <th>Type</th>
                                                                        <th>Comment</th>
                                                                        <th>User</th>
                                                                        <th>Order ID</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    {notification.map((item, i) => (
                                                                        <tr key={item._id}>
                                                                            <td>
                                                                                {i + 1}
                                                                            </td>
                                                                            <td>
                                                                                {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                            </td>
                                                                            <td>
                                                                                {item.type}
                                                                            </td>
                                                                            <td>
                                                                                {item.value}
                                                                            </td>
                                                                            <td>
                                                                                {item.userid ? item.userid.name : null}
                                                                            </td>
                                                                            <td>
                                                                                {item.orderid ? item.orderid.no : null}
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>{/* End #main */}

                    </div>

                </>
                )}

        </>

    )
}

export default Suggestion