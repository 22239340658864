import React, { useEffect, useState } from 'react'
import axios from 'axios';
import UserHeader from './UserHeader';

const MemberSuggestion = () => {

    const usertoken = localStorage.getItem("usertoken");

    const [provider, setProvider] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/user/home`, { headers: { 'usertoken': usertoken } }).then(function (response) {
            // handle success
            setProvider(response.data.providerdata)
            console.log(response.data, "user:::");

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    const [providerorder, setProviderorder] = useState([]);
    const [orderids, setOrderids] = useState({
        orderId: '',
        userId: ''
    });

    // ... (previous code)

    
    const [proid, setProid] = useState([]);

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_URL}/user/accept_order`, { headers: { 'usertoken': usertoken } }).then(function (response) {
            // handle success
            console.log(response.data, "op");
            setProviderorder(response.data.order);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    const [selectedRole, setSelectedRole] = useState('');
    const [desc, setDesc] = useState('');

    const handleSelectChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const handleSelectChanges = (e) => {
        const selectedOption = e.target.value;
        console.log('Selected Option:', selectedOption);

        const [orderId, userId] = selectedOption.split(',');
        console.log('Provider ID:', orderId);
        console.log('Order ID:', userId);

        setOrderids({orderId,userId });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const typedetails = {
            type: selectedRole,
            value: desc,
            orderid: orderids.orderId,
            userid: orderids.userId,
        }

        axios.post(`${process.env.REACT_APP_URL}/user/addsug`, typedetails, { headers: { 'usertoken': usertoken } })
            .then(function (response) {
                console.log(response.data);
                setIsSubmitting(true);
                setDesc('');

                setOrderids({
                
                    orderId: '',
                    userId:''
                });
                window.location.reload();

            })
            .catch(function (error) {
                console.log(error);
            })

    };

    return (
        <>
            {
                (<>
                    <UserHeader />
                    <div>
                        <main id="main" className="main">
                            <div className="pagetitle">
                                <h1 className="text-start m-0" >Suggestion and Complaint</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active">Suggestion & Complaint</li>
                                    </ol>
                                </nav>
                            </div>{/* End Page Title */}

                            <section className="section dashboard">
                                <div className="row">
                                    {/* Left side columns */}
                                    <div className="col-lg-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <label className="col-sm-2 fs-5 col-form-label mb-3 fw-bold"> Add Suggestion or Complaint :</label>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row mb-2">
                                                                <label className="col-sm-2 col-form-label fw-bold" >Select Type</label>
                                                                <div className="col-sm-10">
                                                                    <div className="form-floating mb-4" >
                                                                        <select className="form-control" onChange={handleSelectChange} value={selectedRole}>
                                                                            <option value="">Select</option>
                                                                            <option value="suggestion">Suggestion</option>
                                                                            <option value="complaint">Complaint</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <label className="col-sm-2 col-form-label fw-bold">Add Note:</label>
                                                                <div className="col-sm-10">
                                                                    <div className="form-floating mb-4">
                                                                        <input type="text" className="form-control" id="floatingInput" placeholder="Description" value={desc} onChange={(e) => setDesc(e.target.value)} />
                                                                        <label htmlFor="floatingInput">Enter Your Suggestion or Complaint's Description </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <label className="col-sm-2 col-form-label fw-bold">Select order:</label>
                                                                <div className="col-sm-10">
                                                                    <select
                                                                        className="form-select"
                                                                        aria-label="Default select example"
                                                                        required

                                                                        onChange={handleSelectChanges}
                                                                    >
                                                                        <option hidden>Select Category</option>
                                                                        {providerorder && providerorder.map((item, i) => (
                                                                            <option key={i} value={`${item._id},${item.userid._id}`}>
                                                                                {item.no}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-0 justify-content-end">
                                                                <div className="col-sm-2">
                                                                    <div className="input-group">
                                                                        <input type="submit" className="form-control bg-success text-white" disabled={isSubmitting} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>{/* End #main */}

                    </div>

                </>
                )}

        </>

    )
}

export default MemberSuggestion
