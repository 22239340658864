import React, { useEffect, useState } from "react";
import '../css/style.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AllLogin = ({ onLogin }) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false);
    const nevigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        const userdetail = {
            email: email,
            password: password
        }

        axios.post(`${process.env.REACT_APP_URL}/login`, userdetail)
            .then(function (response) {
                console.log(response.data, "Successfully logged in");

                if (response.data.modelType === 'provider') {
                    const providertoken = response.data.token;
                    localStorage.setItem('providertoken', providertoken);
                    console.log('provider');
                    nevigate('/provider_dashboard')
                } else if (response.data.modelType === 'manager') {
                    const managertoken = response.data.token;
                    localStorage.setItem('managertoken', managertoken);
                    nevigate('/manager_dashboard')
                } else if (response.data.modelType === 'member') {
                    const usertoken = response.data.token;
                    localStorage.setItem('usertoken', usertoken);
                    nevigate('/member')
                }

                onLogin();
                setIsSubmitting(true);
            })
            .catch(function (error) {
                if (error.response && error.response.data) {
                    // Access response.data safely
                    alert(error.response.data);
                } else {
                    console.error("Response or response.data is undefined");
                }

            });

    }


    const handleForgetDev = () => {
        nevigate('/forget_password', { replace: true })
    }

    return (
        <>
            <section className="text-center text-lg-start">

                <div className="container py-4">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="card cascading-right mm">
                                <div className="card-body p-5 shadow-5 text-center">
                                    <img src={require('../img/Logos.png')} style={{ height: '80px' }} alt="Logo" className="img-fluid mb-4" />
                                    <h2 className="fw-bold fs-4 mb-5 mt-5">Sign In</h2>
                                    
                                    <form onSubmit={handleSubmit}>

                                        <div className="form-outline mb-4">
                                            <input type="email" id="form3Example3" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            <label className="form-label" htmlFor="form3Example3">Email address</label>
                                        </div>

                                        <div className="form-outline mb-4">
                                            <input type="password" id="form3Example4" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <label className="form-label" htmlFor="form3Example4">Password</label>
                                        </div>

                                        <div className="form-check form-switch d-flex align-items-center mb-3 justify-content-between">
                                            <div>
                                                <input className="form-check-input" type="checkbox" id="rememberMe" />
                                                <label className="form-check-label mb-0 ms-2" htmlFor="rememberMe">Remember me</label>
                                            </div>
                                            <div>
                                                <label className="form-check-label mb-0 ms-2" onClick={handleForgetDev}>Forgot Password</label>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block mb-4" disabled={isSubmitting}>
                                            Sign In
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="bg-image col-lg-6 mb-5 mb-lg-0 d-none d-lg-block">
                            <img src={require('../img/login-img.jpg')} alt="Login Image" className="w-100 rounded-4 shadow-4 img-fluid" />
                        </div>
                    </div>
                </div>

            </section>
        </>
    )

}

export default AllLogin