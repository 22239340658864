import React from "react";
import ManagerHeader from "./ManagerHeader";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Manager_CancelOrder = () => {

    const managertoken = localStorage.getItem("managertoken");
    const [cancel, setCancel] = useState([])
    // console.log(cancel,"op");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/manager/showdeleteorder`, { headers: { 'managertoken': managertoken } }).then(function (response) {
            // handle success
            console.log(response.data, "Order:::");
            setCancel(response.data);

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])

    const XLSX = require('xlsx');
    const exportToExcel = () => {
        const headers = ['Member ID', 'Member Name', 'Member Number', 'D.O.B.', 'Category', 'Sub Category', 'Product and Service', 'Reference Name', 'Reference Number', 'Description'];
        // Fetch data from the API and store it in the 'data' variable
        const dataAsArray = cancel.map((item) => [
            item.userid.ids,
            item.userid.name,
            item.userid.number,
            item.userid.DOB,
            item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory,
            item.productid.bsubcategoryid[0].bussinesssubcategory,
            item.productid.product,
            item.otherName,
            item.otherNumber,
            item.description,
        ]);

        const excelData = [headers, ...dataAsArray];
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'exported_data.xlsx');
    }



    return (
        <>

            <ManagerHeader />


            <div>
                <main id="main" className='main'>
                    <div className='pagetitle'>
                        <h1 className='text-start m-0'>All Deleted Order</h1>
                        <nav>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>Home</li>
                                <li className='breadcrumb-item activ'>Show Order</li>
                            </ol>
                        </nav>
                    </div>

                    <section className="section dashboard">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">

                                    <div class="card card-body">
                                        <div className=" d-flex">
                                            <div className=" me-3">
                                                <button className="btn btn-success" style={{ width: 'fit-content' }} onClick={exportToExcel}>Export Excel</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive mt-2">
                                <table className="table">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Provider Number</th>
                                            <th>Customer Name</th>
                                            <th>Customer Number</th>
                                            <th>Category</th>
                                            <th>Sub Category</th>
                                            <th>Product and Service</th>
                                            <th>Description</th>
                                            {/* <th>Show</th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="table-light">
                                        {/* {console.log(cancel,"ppppp")} */}
                                        {/* {console.log(cancel,"pou")} */}
                                        {cancel &&
                                            cancel.map((item, i) => (
                                                <tr key={i}>


                                                    <td>
                                                        <h6 className="">
                                                            {item.no}
                                                        </h6>
                                                    </td>
                                                    <td>
                                                    {console.log(item.decproviderid , "can")}
                                                        {item.declineproviderid && item.declineproviderid.map((ite, i) => (
                                                            <h6 className="">{i+1}{")"} {ite.number}, </h6>
                                                        ))}

                                                    </td>
                                                    <td>
                                                        <h6 className="">
                                                            {item.otherName}
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="">
                                                            {item.otherNumber}
                                                        </h6>
                                                    </td>

                                                    <td>
                                                        <h6 className="">
                                                            {item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="">
                                                            {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="">
                                                            {item.productid.product}
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="">
                                                            {item.description}
                                                        </h6>
                                                    </td>
                                                </tr>

                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </main>
                
            </div >
        </>
    )
}
export default Manager_CancelOrder;