import React, { useEffect, useState } from "react";
import Header from "./Provider_Header";
import { useParams } from "react-router-dom";
import axios from "axios";

const Orderdetails = () => {

    // // loader
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 1200);
        });
    };




    //  Show Member data
    const [order, setOrder] = useState([])
    const [provider, setprovider] = useState([])
    const id = useParams()
    const orderid = id.id
    const token = localStorage.getItem("providertoken");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/provider/userform_details/${orderid}`, { headers: { 'providertoken': token } }).then(function (response) {
            // handle success
            console.log(response.data, "dsdsdsd");
            setOrder([response.data.order]);
            setprovider([response.data.provider]);
            //  setTempservice(response.data.user);

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [orderid]);
    return (
        <>
            {
                (<>

                    <Header />
                    <main id="main" className="main">
                        <div className="pagetitle">
                            <h1 className="text-start m-0">Orders Details</h1>
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Notification</li>
                                    <li className="breadcrumb-item active">Show Order</li>
                                </ol>
                            </nav>
                        </div>{/* End Page Title */}

                        <section className="section dashboard">
                            <div className="row">
                                {/* Provider Data */}
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card recent-sales overflow-auto">
                                                <div className="card-body">
                                                    <h5 className="card-title">Order Details :</h5>
                                                    <div className="pe-4 col-12 text-end">
                                                        {/* <Link><button  className="btn btn-danger px-5 me-2 w-25 mb-3">Delete</button></Link> */}
                                                    </div>

                                                    {order.map((item, i) => (
                                                        <div className='prodetails'>
                                                            <div className="col-12 border shadow-sm p-3 mb-2 bg-body rounded">
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Product Category :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Product Subcategory:</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Product and Service :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.productid.product}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Description :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.description}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Customer Name :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.otherName}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Customer Numbar :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.otherNumber}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12 ">
                                                                    <div className="col-4">
                                                                        <p><strong>Customer Email ID :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.otherEmail}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-4">
                                                                        <p><strong>Order ID :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.no}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-4">
                                                                        <p><strong>Budget :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.budget}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-4">
                                                                        <p><strong>Order Date :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.updatedAt.slice(0, 10)}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 d-flex col-12">
                                                                    <div className="col-4">
                                                                        <p><strong>Order Description :</strong></p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p>
                                                                            <span className="fs-6">
                                                                                {item.trackingorder[0].description}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <h5 className="card-title">Provider Details:</h5>
                                                    {provider &&
                                                        Array.isArray(provider) &&
                                                        provider.map(innerArray => (
                                                            innerArray &&
                                                            Array.isArray(innerArray) &&
                                                            innerArray.map(item => (
                                                                <div key={item._id} className="border shadow mb-2 rounded-3 py-3 d-flex flex-column flex-lg-row align-items-center">
                                                                    <div className="col-lg-6">
                                                                        {/* Left side content */}
                                                                        <div className="ms-3 d-flex flex-column flex-lg-row col-12">
                                                                            <div className="mb-4 col-12 col-lg-4">
                                                                                <img src={item.profile} height={"150px"} alt={item.name} className="img-fluid" />
                                                                            </div>
                                                                            <div className="col-12 col-lg-8">
                                                                                <div className="mb-2">
                                                                                    <p><strong>Provider Name:</strong></p>
                                                                                    <p>{item.name}</p>
                                                                                </div>
                                                                                <div className="mb-2">
                                                                                    <p><strong>Email:</strong></p>
                                                                                    <p>{item.email}</p>
                                                                                </div>
                                                                                <div className="mb-2">
                                                                                    <p><strong>Contact Number:</strong></p>
                                                                                    <p>{item.number}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        {/* Right side content */}
                                                                        <div className="ms-3 d-flex flex-column flex-lg-row col-12">
                                                                            <div className="mb-2 col-12 col-lg-4">
                                                                                <p><strong>Address:</strong></p>
                                                                                <p>{item.address}</p>
                                                                            </div>
                                                                            <div className="mb-2 col-12 col-lg-8">
                                                                                <p><strong>Business Type:</strong></p>
                                                                                <p>{item.Btype}</p>
                                                                            </div>
                                                                            {/* Add more properties as needed */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>


                        </section>

                    </main>
                </>)}
        </>
    )
}
export default Orderdetails;