import React, { useEffect, useState } from "react"
import Header from "./Header"
import axios from "axios"
import { Link } from "react-router-dom"
import Loader from "./Loader"


const Allorder = () => {

    //loarder
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmittingexcel, setIsSubmittingexcel] = useState(false);
    const [isSubmittingM, setIsSubmittingM] = useState(false);
    const [isSubmittingP, setIsSubmittingP] = useState(false);
    const [isSubmittingC, setIsSubmittingC] = useState(false);
    const [data1, setData] = useState(null)

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result)
            setIsLoading(false)
        })
    }, [])

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API")
            }, 1000)
        })
    }





    const [userForms, setUserForms] = useState([])
    const token = localStorage.getItem("token")

    var allorder = () => {
        axios.get(`${process.env.REACT_APP_URL}/admin/all_userform`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data)
            setUserForms(response.data.userForms)
        })
            .catch(function (error) {
                // handle error
                console.log(error)
            })
    }

    useEffect(() => {
        allorder()
    }, [])

    // 7300

    const [managers, setManagers] = useState('')
    var handlemanager = (e) => {
        e.preventDefault()
        axios.get(`${process.env.REACT_APP_URL}/admin/allmanager`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            // console.log(response.data)
            setManagers(response.data.managers)
            setIsSubmittingM(true)
        })
            .catch(function (error) {
                // handle error
                console.log(error)
            })
    }

    const XLSX = require('xlsx')
    const exportToExcel = () => {
        const headers = ['Date','Member ID', 'Member Name', 'Member Number', 'D.O.B.', 'Category', 'Sub Category', 'Product and Service', 'Reference Name', 'Reference Number', 'Description']
        // Fetch data from the API and store it in the 'data' variable
        const dataAsArray = filteredData.map((item) => [
            item.createdAt,
            item.userid.ids,
            item.userid.name,
            item.userid.number,
            item.userid.DOB,
            item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory,
            item.productid.bsubcategoryid[0].bussinesssubcategory,
            item.productid.product,
            item.otherName,
            item.otherNumber,
            item.description,
        ])

        const excelData = [headers, ...dataAsArray]
        const ws = XLSX.utils.json_to_sheet(excelData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        XLSX.writeFile(wb, 'exported_data.xlsx')
        setIsSubmittingexcel(true);
    }



    //Order Multiple Select Options

    const [selectedManagerIds, setSetSelectedManagerIds] = useState([])

    // Function to handle checkbox changes
    const handleManagerCheckboxChange = (itemId) => {
        if (selectedManagerIds.includes(itemId)) {
            // If the ID is already in the array, remove it
            setSetSelectedManagerIds(selectedManagerIds.filter(id => id !== itemId))
        } else {
            // If the ID is not in the array, add it
            setSetSelectedManagerIds([...selectedManagerIds, itemId])
        }
    }
    // console.log(selectedProviderIds,"Provider")

    //Order Multiple Select Options

    const [selectedOrderIds, setSelectedOrderIds] = useState([])

    // Function to handle checkbox changes
    const handleCheckboxChange = (itemId) => {
        if (selectedOrderIds.includes(itemId)) {
            // If the ID is already in the array, remove it
            setSelectedOrderIds(selectedOrderIds.filter(id => id !== itemId))
        } else {
            // If the ID is not in the array, add it
            setSelectedOrderIds([...selectedOrderIds, itemId])
        }
    }
    //  console.log(selectedOrderIds,"Order")


    var handlecancel = () => {

        axios.post(`${process.env.REACT_APP_URL}/admin/deleteorder`, selectedOrderIds, { headers: { 'token': token } })
            .then(function (response) {
                console.log(response.data, "p")
                // window.location.reload()
                allorder()
                setIsSubmittingC(true);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response)
            })

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const OrderData = {
            Orderid: selectedOrderIds,
            Managerid: selectedManagerIds
        }

        // data get karavava mate
        axios.post(`${process.env.REACT_APP_URL}/admin/forward_order`, OrderData, { headers: { 'token': token } })
            .then(function (response) {
                console.log(response.data, "p")
                allorder()
                // window.location.reload()
            })
            .catch(function (error) {
                // handle error
                console.log(error.response)
                if (error.response.data.run == true) {

                    axios.post(`${process.env.REACT_APP_URL}/admin/forward_order_provider`, OrderData, { headers: { 'token': token } })
                        .then(function (response) {
                            console.log(response.data, "p")
                            window.location.reload()
                            allorder()
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error.response)
                        })

                }
            })

    }



    // search Input
    const [searchInput, setSearchInput] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [showCancelledOrders, setShowCancelledOrders] = useState(false);
    const [showProviderSended, setShowProviderSended] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);
    const [showCompletedManager, setShowCompletedManager] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showCancelProvider, setShowCancelProvider] = useState(false);
    // Assuming your initial data is stored in the `adminorder` state
    // const [adminorder, setAdminOrder] = useState([])

    useEffect(() => {
        // Filter the data based on the search input and cancelled order flag
        const filtered = userForms.filter((item) => {
            const containsSearchInput =
                item.userid.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                (typeof item.userid.number === 'string' && item.userid.number.includes(searchInput)) ||
                (item.no.toString().includes(searchInput.toLowerCase())) ||
                item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                item.productid.bsubcategoryid[0].bussinesssubcategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                item.productid.product.toLowerCase().includes(searchInput.toLowerCase());

            const isCancelledOrder = showCancelledOrders ? item.color === undefined : true;
            const isProviderSended = showProviderSended ? item.color === "#0080002b" : true;
            const isCompleted = showCompleted ? item.color === "#d93342a6" : true;
            const isCompletedManager = showCompletedManager ? item.color === "#0033804a" : true;
            const isCanceledManager = showCancel ? item.color === "#ffd40057" : true;
            const isCanceledProvider = showCancelProvider ? item.color === "#E69138" : true;

            return containsSearchInput && isCancelledOrder && isProviderSended && isCompleted && isCompletedManager && isCanceledManager && isCanceledProvider;
        });

        setFilteredData(filtered);
    }, [searchInput, showCancelledOrders, showProviderSended, showCompleted, showCompletedManager, showCancel, showCancelProvider, userForms]);

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    // Handler for the checkbox to toggle cancelled orders
    const handleCancelledOrdersToggle = () => {
        setShowCancelledOrders(!showCancelledOrders);
    };

    const handleProvidersended = () => {
        setShowProviderSended(!showProviderSended);
    };
    const handleCompleted = () => {
        setShowCompleted(!showCompleted);
    };
    const handleCompletedManager = () => {
        setShowCompletedManager(!showCompletedManager);
    };
    const handleCompletedManagerCancel = () => {
        setShowCancel(!showCancel);
    };
    const handleCompletedManagerCancelProvider = () => {
        setShowCancelProvider(!showCancelProvider);
    };

    const [seletedOption, setSeletedOption] = useState(false)
    const [providers, setProvider] = useState('')
    // Provider show data
    const hendleProvider = (e) => {
        e.preventDefault()
        // useEffect(() => {
        axios.post(`${process.env.REACT_APP_URL}/admin/allprovider`, selectedOrderIds, { headers: { 'token': token } })
            .then(function (response) {
                // handle success
                console.log(response.data, 'SDSDS')
                setManagers(response.data.providers)
                if (response.data.ds == true) {
                    var pro = response.data.pro
                    const newProviders = []

                    for (const orderId of selectedOrderIds) {
                        const orderData = response.data.order.find((order) => order._id === orderId)
                        console.log(orderId)
                        const productId = orderData.productid

                        const productData = response.data.product.find((product) => product._id === productId)
                        const bsubcategoryIds = productData.bsubcategoryid

                        for (const bsubcategoryId of bsubcategoryIds) {
                            const bsubcate = response.data.bsubcategory.find((bs) => bs._id === bsubcategoryId)

                            for (const provider of response.data.provider) {
                                for (const l of provider.bsubcategoryid) {
                                    const bsubcategorydata = response.data.bsubcategory.find((bs) => bs._id === l)

                                    function bcat(id) {
                                        return response.data.bcategory.find((bc) => bc._id === id)
                                    }
                                    const ds = bcat(bsubcategorydata.bcategoryid)
                                    const sd = bcat(bsubcate.bcategoryid)

                                    if (ds._id === sd._id) {
                                        newProviders.push(provider)
                                    }
                                }
                            }
                        }
                    }

                    console.log("newProviders:", newProviders);
                    console.log("pro:", pro);

                    const filteredProviders = newProviders.filter(provider => !pro.some(p => p._id.toString() === provider._id.toString()));
                    console.log("filteredProviders:", filteredProviders);

                    function removeDuplicates(arr) {
                        const uniqueMap = new Map()
                        const result = []

                        for (const item of arr) {
                            const key = JSON.stringify(item)
                            if (!uniqueMap.has(key)) {
                                uniqueMap.set(key, true)
                                result.push(item)
                            }
                        }
                        return result
                    }
                    console.log(filteredProviders)
                    setManagers(removeDuplicates(filteredProviders))
                }
                setSeletedOption(true)
                setIsSubmittingP(true);
            })
            .catch(function (error) {
                if (error.response) {
                    alert(error.response.data.message)
                }
                else if (error.request) {
                    alert('Network error. Please try again')
                }
                else {
                    alert('An error occurred. Please try again')
                    console.log(error, 'error')
                }
            })
        // }, [])
    }

    const colorData = [
        { background: "#B6D9B6", color: "#00000", text: "Order Sended to Provider" },
        { background: "#DF4B5A", color: "#00000", text: "Order Completed" },
        { background: "#0033804a", color: "#00000", text: "Order Sended to Manager" },
        { background: "#E69138", color: "#00000", text: "Accepted Order Cancel" },
        { background: "#FEE786", color: "#00000", text: "Order Sended to Cancel" },
        { background: "#ffffff", color: "#00000", text: "New Order" },
        // Add more color data as needed
    ];
    return (
        <>

            {
                (<>
                    <Header />
                    <div>
                        <main id="main" className='main'>
                            <div className='pagetitle'>
                                <h1 className='text-start m-0'>Allorder</h1>
                                <nav>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'>Home</li>
                                        <li className='breadcrumb-item activ'>Order</li>
                                    </ol>
                                </nav>
                            </div>

                            <section className="section dashboard">
                                <div className="row">
                                    {/* Left side columns */}
                                    <div className="col-lg-12">
                                        <div className="row">

                                            <div className="col-12">
                                                <div class="collapse col-12" id="collapseExample">
                                                    <div class="card card-body">
                                                        {managers && managers.map((item, i) => (
                                                            <div key={i}>
                                                                <p>
                                                                    <span>
                                                                        <input
                                                                            className="form-check-input me-3"
                                                                            type="checkbox"
                                                                            value={item._id}
                                                                            id="flexCheckMDefault"
                                                                            onChange={() => handleManagerCheckboxChange(item._id)}
                                                                            checked={selectedManagerIds.includes(item._id)}
                                                                        />
                                                                    </span>
                                                                    <strong>
                                                                        {item?.name} - {item.Bname}
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                        ))}
                                                        <button onClick={handleSubmit} className="btn btn-dark" style={{ width: 'fit-content' }}>Send</button>
                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        {/* <!-- Search Input --> */}
                                                        <div className="">
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                    placeholder="Search Term"
                                                                    value={searchInput}
                                                                    onChange={handleSearchInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5 className="fw-bold mt-2">Number of Orders : {filteredData.length}</h5>
                                                        </div>
                                                        {/* <!-- Table Actions --> */}
                                                        <div className="d-flex flex-wrap">
                                                            <div className="mb-3">
                                                                <button className="btn btn-success me-2" style={{ width: 'fit-content' }} onClick={exportToExcel} disabled={isSubmittingexcel}>Export to Excel</button>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button className="btn btn-primary me-2" style={{ width: 'fit-content' }} onClick={handlemanager} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" disabled={isSubmittingM}>
                                                                    Send to Manager
                                                                </button>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button className="btn btn-primary me-2" style={{ width: 'fit-content' }} onClick={hendleProvider} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" disabled={isSubmittingP}>
                                                                    Send to Provider
                                                                </button>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button className="btn btn-danger" style={{ width: 'fit-content' }} onClick={handlecancel} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" disabled={isSubmittingC}>
                                                                    Send to Cancel
                                                                </button>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="cancelOrderCheckbox" className="mb-0 fw-bold d-flex">
                                                                    <input type="checkbox" className="border rounded-2 me-2 ms-3" style={{ backgroundColor: "#ffd40057", height: "20px", width: "20px" }} checked={showCancelledOrders}
                                                                        onChange={handleCancelledOrdersToggle} />
                                                                    New order
                                                                </label>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="cancelOrderCheckbox" className="mb-0 fw-bold d-flex">
                                                                    <input type="checkbox" className="border rounded-2 me-2 ms-3" style={{ backgroundColor: "#0080002b", height: "20px", width: "20px" }} checked={showProviderSended}
                                                                        onChange={handleProvidersended} />
                                                                    <span style={{ backgroundColor: "#B6D9B6" }}>Sended to provider</span>
                                                                </label>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="cancelOrderCheckbox" className="mb-0 fw-bold d-flex" >
                                                                    <input type="checkbox" className="border rounded-2 me-2 ms-3" style={{ backgroundColor: "#d93342a6", height: "20px", width: "20px" }} checked={showCompleted}
                                                                        onChange={handleCompleted} />
                                                                    <span style={{ backgroundColor: "#DF4B5A" }}>Completed Order</span>
                                                                </label>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="cancelOrderCheckbox" className="mb-0 fw-bold d-flex">
                                                                    <input type="checkbox" className="border rounded-2 me-2 ms-3" style={{ backgroundColor: "#0040804a", height: "20px", width: "20px" }} checked={showCompletedManager}
                                                                        onChange={handleCompletedManager} />
                                                                    <span style={{ backgroundColor: "#0033804a" }}>Sended to  manager</span>
                                                                </label>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="cancelOrderCheckbox" className="mb-0 fw-bold d-flex">
                                                                    <input type="checkbox" className="border rounded-2 me-2 ms-3" style={{ backgroundColor: "#0040804a", height: "20px", width: "20px" }} checked={showCancel}
                                                                        onChange={handleCompletedManagerCancel} />
                                                                    <span style={{ backgroundColor: "#FEE786" }}>Sended to  Cancel</span>
                                                                </label>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="cancelOrderCheckbox" className="mb-0 fw-bold d-flex">
                                                                    <input type="checkbox" className="border rounded-2 me-2 ms-3" style={{ backgroundColor: "#0040804a", height: "20px", width: "20px" }} checked={showCancelProvider}
                                                                        onChange={handleCompletedManagerCancelProvider} />
                                                                    <span style={{ backgroundColor: "#E69138" }}>Accepted Order Cancel</span>
                                                                </label>
                                                            </div>
                                                        </div>


                                                        <div className="table-responsive mt-2">
                                                            <table className="table">
                                                                <thead className="table-primary text-center">
                                                                    <tr>
                                                                        <th>Select</th>
                                                                        <th>Order Date</th>
                                                                        <th>Order ID</th>
                                                                        <th>Member ID</th>
                                                                        <th>Member Name</th>
                                                                        <th>Member Number</th>
                                                                        <th>Category</th>
                                                                        <th>Sub Category</th>
                                                                        <th>Product and Service</th>
                                                                        <th>Show</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    <>
                                                                        {
                                                                            filteredData.map((item, i) => (
                                                                                <tr key={i} style={{ background: item.color }}>

                                                                                    <td style={{ background: item.color }}>
                                                                                        <div class="form-check">
                                                                                            {item.color === "#0080002b" || item.color === "#d93342a6" ?
                                                                                                () => { } // If the color is "#0080002b", render an empty function
                                                                                                : (
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        value={item._id}
                                                                                                        id="flexCheckDefault"
                                                                                                        onChange={() => handleCheckboxChange(item._id)}
                                                                                                        checked={selectedOrderIds.includes(item._id)}
                                                                                                    />
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.no}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.userid.ids}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.userid.name}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.userid.number}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                                    </td>
                                                                                    <td style={{ background: item.color }}>
                                                                                        {item.productid.product}
                                                                                    </td>

                                                                                    <td data-th="Net Amount" style={{ background: item.color }}>
                                                                                        <Link to={`/admin_orderdetails/${item._id}`}>
                                                                                            <button type="button" style={{ width: 'fit-content' }} className="btn btn-primary btn-sm"> Show</button>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>

                                                                            ))}
                                                                    </>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* <div>
                                                            {colorData.map((item, index) => (
                                                                <div key={index} className="color-box">
                                                                    <div className="box" style={{ background: item.background }}></div>
                                                                    <div className="info" style={{ color: item.color }}>{item.text}</div>
                                                                </div>
                                                            ))}
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>

                    </div >

                </>)}

        </>
    )

}
export default Allorder
