import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Forget_Password = () => {

    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const nevigate = useNavigate()

    
    const handleSubmit = (e) => {
        localStorage.setItem('Email', email)
        e.preventDefault();
        const Forget = {
            email: email,
        }

        axios.post(`${process.env.REACT_APP_URL}/otp`, Forget)
            .then(function (response) {
                // handle success
                console.log(response.data);
                nevigate('/reset_password',{ replace: true })
                setIsSubmitting(true);
            })
            .catch(function (error) {
                console.log(error);
                if (error.response) {
                    alert(error.response.data.message);
                  } else if (error.request) {
                    alert('Network error. Please try again later.');
                  } else {
                    alert('An error occurred. Please try again later.');
                  }
            })


    }

    return (
        <>
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center min-vh-100 g-0">
                    <div className="col-12 col-md-8 col-lg-4 border-top border-3 border-primary">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <img src={require('../img/Logos.png')} style={{ height: '80px' }} alt="Logo" className="img-fluid mb-4" />
                                <div className="mb-4">
                                    <h5>Forgot Password?</h5>
                                    <p className="mb-2">Enter your registered email ID to reset the password</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Email</label>
                                        <input type="email" id="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Enter Your Email" required />
                                    </div>
                                    <div className="mb-3 d-grid">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                            Send Your OTP
                                        </button>
                                    </div>
                                    <span>Already have an account? <a href="/">Sign in</a></span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default Forget_Password;