import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import Header from "../admin/Header";
import Loader from "../admin/Loader";

const DashboardShowOrder = () => {


    // // loader
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState(null);

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 1000);
        });
    };




    const token = localStorage.getItem("token");

    //  Show Member data
    const [order, setOrder] = useState('')
    const id = useParams()

    console.log('ids',id)
    const orderid = id.id
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/trackorder_detail/${orderid}`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data, "dsdsdsd");
            setOrder([response.data.orders]);
            //  setTempservice(response.data.user);

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [orderid]);

    const originalDate = '2023-11-07T09:09:58.492Z';

    const formattedDate = moment(originalDate).format('MMMM Do YYYY, h:mm:ss a');

    return (
        <>
            {
                (<>
                    <Header />
                    <main id="main" className="main">
                        <div className="pagetitle">
                            <h1 className="text-start m-0">Orders Details</h1>
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">Notification</li>
                                    <li className="breadcrumb-item active">Show Order</li>
                                </ol>
                            </nav>
                        </div>{/* End Page Title */}
                        <section className="section dashboard">
                            <div className="row">
                                {/* Provider Data */}
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card recent-sales overflow-auto">
                                                <div className="card-body">
                                                    <h5 className="card-title">Order Details :</h5>
                                                    {console.log(order, "i")}

                                                    {order && order.map((item, i) => (
                                                        <div className='prodetails'>
                                                            {item.trackingorder?.[0] && (
                                                                <div className="col-12 border shadow-sm p-3 mb-5 bg-body rounded">
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Member ID :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.userid.ids}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Member Name :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.userid.name}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Member Numbar :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.userid.number}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Product Category :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {/* {console.log(item,"kkkkkkk")} */}

                                                                                    {item.trackingorder[0].orderid.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Product Subcategory:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Product and Service :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.productid.product}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Description :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.description}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Customer Name :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.otherName}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Customer Numbar :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.otherNumber}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12 ">
                                                                        <div className="col-6">
                                                                            <p><strong>Customer Email ID :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.otherEmail}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Order ID :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.no}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Budget :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].orderid.budget}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Order Date :</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {new Date(item.createdAt).toLocaleString()}
                                                                                    {/* {item.updatedAt} */}
                                                                                    {/* {item.updatedAt.slice(0, 10)} */}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Accept Order:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].status === true ? (<span><i class="bi-check-lg fs-4 text-success"></i></span>) : (<span className="text-danger fw-bold">Order Accept pending</span>)}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Call:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].call === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Call pending</span>}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Meeting:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].meeting === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Meeting pending</span>}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Deal Done:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].deal === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Order Deal pending</span>}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Amount:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-5 text-success fw-bold">
                                                                                    {item.trackingorder[0].dealamount}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Deal Amount:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].amount === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Deal Amount pending</span>}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Work Done:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].work === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Order Work pending</span>}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>PayMent Done:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].payment === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">PayMent pending</span>}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ms-3 d-flex col-12">
                                                                        <div className="col-6">
                                                                            <p><strong>Order Description:</strong></p>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p>
                                                                                <span className="fs-6">
                                                                                    {item.trackingorder[0].description}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {item.trackingorder?.[0]?.orderid?.providerid && item.trackingorder[0].orderid.providerid.map(is => (
                                                                <div key={is._id} className="border shadow mb-2 rounded-3 py-3 d-flex  align-items-center flex-row">
                                                                    {console.log(item, "op")}
                                                                    <div className="col-lg-12 ps-5">
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-12 mb-4">
                                                                                <img src={is.profile} height={"150px"} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider Name:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{is.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider call:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{is.number}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider email:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{is.email}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}



                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>


                        </section>

                    </main>
                </>
                )}
        </>
    )
}
export default DashboardShowOrder;