import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../admin/Header";
import Loader from "../admin/Loader";

const DashboardOrder = () => {


    // // loader
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState(null);

    useEffect(() => {
        // Simulate an API call
        fetchData().then((result) => {
            setData(result);
            setIsLoading(false);
        });
    }, []);

    const fetchData = async () => {
        // Simulate an API call or any asynchronous operation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Data from API");
            }, 1000);
        });
    };




    const token = localStorage.getItem("token");

    const [orders, setOrders] = useState('');
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/admin/accepted_order`, { headers: { 'token': token } }).then(function (response) {
            // handle success
            console.log(response.data);
            setOrders(response.data.order || []);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }, [])


    const XLSX = require('xlsx');
    const exportToExcel = () => {
        const headers = ['Member ID', 'Member Name', 'Member Number', 'D.O.B.', 'Category', 'Sub Category', 'Product and Service', 'Reference Name', 'Reference Number', 'Description'];
        // Fetch data from the API and store it in the 'data' variable
        const dataAsArray = orders.map((item) => [
            item.userid.ids,
            item.userid.name,
            item.userid.number,
            item.userid.DOB,
            item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory,
            item.productid.bsubcategoryid[0].bussinesssubcategory,
            item.productid.product,
            item.otherName,
            item.otherNumber,
            item.description,
        ]);

        const excelData = [headers, ...dataAsArray];
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'exported_data.xlsx');
    }

    const [searchInput, setSearchInput] = useState('')
    const [filteredData, setFilteredData] = useState([])
    // Assuming your initial data is stored in the `adminorder` state
    // const [adminorder, setAdminOrder] = useState([]);

    useEffect(() => {
        // Filter the data based on the search input
        // console.log(orders.length,"o")
        if (searchInput.length == 0) {
            setOrders(orders);
        }
        if (orders.length == 0) {
            setOrders(orders);
        } else {
            // console.log('KD', orders);
            const filtered = orders.filter((item) => {
                return (
                    item.providerid[0]?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    (typeof item.userid.number === 'string' && item.userid.number.includes(searchInput)) ||
                    (item.no.toString().includes(searchInput.toLowerCase())) ||  // Convert to string
                    item.otherName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.productid.bsubcategoryid[0].bussinesssubcategory.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.productid.product.toLowerCase().includes(searchInput.toLowerCase())
                ) 
            }) 

            setFilteredData(filtered)
        }
        console.log(searchInput);
    }, [searchInput, orders])

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value)
    };

    return (
        <>

            {
                (<>
                    <Header />
                    <div>
                        <main id="main" className='main'>
                            <div className='pagetitle'>
                                <h1 className='text-start m-0'>Accepted Order</h1>
                                <nav>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'>Home</li>
                                        <li className='breadcrumb-item activ'>Accepted Order</li>
                                    </ol>
                                </nav>
                            </div>
                            <section className="section dashboard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div class="collapse col-12" id="collapseExample">

                                                </div>
                                                <div className="card recent-sales overflow-auto">
                                                    <div className="card-body">
                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                                placeholder="Search Term"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5 className="fw-bold">number of Accepted Order : {orders.length}</h5>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="mb-2">
                                                                <button className="btn btn-success" onClick={exportToExcel}>Export Excel</button>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive mt-2">
                                                            <table className="table">
                                                                <thead className="table-primary text-center">

                                                                    <tr>
                                                                        <th>Date</th>
                                                                        <th>Order Id</th>
                                                                        <th>Provider Id</th>
                                                                        <th>Customer Name</th>
                                                                        <th>Customer Number</th>
                                                                        <th>Provider Name</th>
                                                                        <th>Provider Numbar</th>
                                                                        <th>Category</th>
                                                                        <th>Sub Category</th>
                                                                        <th>Product and Service</th>
                                                                        <th>Description</th>
                                                                        <th>Show</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-light text-center">
                                                                    <>
                                                                        {filteredData &&
                                                                            filteredData.map((item, i) => (
                                                                                <tr key={i}>


                                                                                    <td>
                                                                                        {new Date(item.createdAt).toLocaleDateString('en-GB')}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.no}
                                                                                    </td>
                                                                                    <td>{item.providerid[0].ids}</td>
                                                                                    <td>
                                                                                        {item.otherName}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.otherNumber}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.providerid[0].name}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.providerid[0].number}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.productid.product}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.description}
                                                                                    </td>
                                                                                    <td data-th="Net Amount">
                                                                                        <Link to={`/admin_showacceptorder/${item._id}`}>
                                                                                            <button type="button" className="btn btn-primary btn-sm">Show</button>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>

                                                                            ))}
                                                                    </>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>

                    </div >
                </>)}
        </>
    )
}
export default DashboardOrder;
