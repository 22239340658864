import React from "react";
import UserHeader from "./UserHeader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import moment from 'moment';

const Showorder = () => {
    //  Show Member data
    const [order, setOrder] = useState('')
    const id = useParams()
    const orderid = id.id
    const usertoken = localStorage.getItem("usertoken")
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/user/order_detail/${orderid}`, { headers: { 'usertoken': usertoken } }).then(function (response) {
            // handle success
            console.log(response.data, "dsdsdsd")
            setOrder([response.data])
            //  setTempservice(response.data.user);
        })
            .catch(function (error) {
                // handle error
                console.log(error)
            })
    }, [orderid])

    const originalDate = '2023-11-07T09:09:58.492Z';
    const formattedDate = moment(originalDate).format('MMMM Do YYYY, h:mm:ss a')
    return (
        <>
            <UserHeader />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1 className="text-start m-0">Orders Details</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Home</li>
                            <li className="breadcrumb-item active">Notification</li>
                            <li className="breadcrumb-item active">Show Order</li>
                        </ol>
                    </nav>
                </div>{/* End Page Title */}
                <section className="section dashboard">
                    <div className="row">
                        {/* Provider Data */}
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card recent-sales overflow-auto">
                                        <div className="card-body">
                                            <h5 className="card-title">Order Details :</h5>
                                            {order && order.map((item, i) => (
                                                <div className='prodetails'>
                                                    <div className="col-12  mb-5 bg-body rounded">
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Member ID :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.userid.ids}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Member Name :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.userid.name}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Member Numbar :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.userid.number}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Product Category :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.productid.bsubcategoryid[0].bcategoryid.bussinesscategory}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Product Subcategory:</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.productid.bsubcategoryid[0].bussinesssubcategory}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Product and Service :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.productid.product}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Description :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.description}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Customer Name :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.otherName}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Customer Numbar :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.otherNumber}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12 ">
                                                            <div className="col-6">
                                                                <p><strong>Customer Email ID :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.otherEmail}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12">
                                                            <div className="col-6">
                                                                <p><strong>Order ID :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.no}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12">
                                                            <div className="col-6">
                                                                <p><strong>Budget :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item.order.budget}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12">
                                                            <div className="col-6">
                                                                <p><strong>Order Date :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {new Date(item.order.updatedAt).toLocaleString()}
                                                                        {/* {item.updatedAt} */}
                                                                        {/* {item.updatedAt.slice(0, 10)} */}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="ms-1 d-flex col-12">
                                                            <div className="col-6">
                                                                <p><strong>Order Description :</strong></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>
                                                                    <span className="fs-6">
                                                                        {item && item.trackingorder[0] ? item.trackingorder[0].description : null}
                                                                        
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.order.declineproviderid.length > 0 ? (<>Order Cancelled by provider</>): (<>{item.user.map(is => (
                                                                <div key={is._id} className="border shadow mb-2 rounded-3 py-3 align-items-center flex-row">
                                                                    <div className="col-lg-12 ps-2">
                                                                        {/* <div className="ms-3 d-flex col-12">
                                                                            <div className="col-12 mb-4">
                                                                                <img src={is.provider.img} height={"150px"} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider Name:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{is.provider.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider call:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{is.provider.number}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider email:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{is.provider.email}</p>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Last update:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>{new Date(is.provider.updatedAt).toLocaleString()}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 ps-2">
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Provider Accept Order:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {is.trackingorder.status === true ? (
                                                                                            <span><i className="bi-check-lg fs-4 text-success"></i></span>
                                                                                        ) : (
                                                                                            <span className="text-danger fw-bold">Order Accept Pending</span>
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong> Call:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {is.trackingorder.call === true ? (
                                                                                            <span><i className="bi-check-lg fs-4 text-success"></i></span>
                                                                                        ) : (
                                                                                            <span className="text-danger fw-bold">Call Pending</span>
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Meeting:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {is.trackingorder.meeting === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Meeting Panding</span>}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Deal Done:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {is.trackingorder.deal === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Order Deal Panding</span>}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Amount:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-5 text-success fw-bold">
                                                                                        {is.trackingorder.dealamount}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Deal Amount:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {console.log(is.trackingorder.amount)}
                                                                                        {is.trackingorder.dealamount.length != "0" ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Deal Amount Panding</span>}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>Work Done:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {is.trackingorder.work === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">Order Work Panding</span>}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3 d-flex col-12">
                                                                            <div className="col-6">
                                                                                <p><strong>PayMent Done:</strong></p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <p>
                                                                                    <span className="fs-6">
                                                                                        {is.trackingorder.payment === true ? <span><i class="bi-check-lg fs-4 text-success"></i></span> : <span className="text-danger fw-bold">PayMent Panding</span>}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}</>)
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                </section>
            </main>
        </>
    )
}
export default Showorder;